export const hourglass = <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.54507 7.93173L10.5861 11.9467V14.3454L8.96683 15.9484H6.87812L0.975156 21.7924V23.4556H20.5306V21.6575L14.7639 15.9484H12.6296L10.9796 14.3149V11.8879L14.9757 7.93173H6.54507Z" fill="#4B4BFF" />
    <path d="M0.536133 23.8902V21.5976L10.1928 12.0375L0.626949 2.59728V0.109863H20.9998V2.59728L11.4642 12.0375L20.9998 21.4477V23.8902H0.536133ZM1.42915 23.0211H20.0916V21.8523L10.8134 12.6968L1.42915 21.9872V23.0211ZM1.50483 2.23766L10.8134 11.4231L20.0916 2.23766V1.00893H1.50483V2.23766Z" fill="#302F38" />
</svg>




export const hourglass_red = <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.54507 7.93173L10.5861 11.9467V14.3454L8.96683 15.9484H6.87812L0.975156 21.7924V23.4556H20.5306V21.6575L14.7639 15.9484H12.6296L10.9796 14.3149V11.8879L14.9757 7.93173H6.54507Z" fill="#4B4BFF" />
    <path d="M0.536133 23.8902V21.5976L10.1928 12.0375L0.626949 2.59728V0.109863H20.9998V2.59728L11.4642 12.0375L20.9998 21.4477V23.8902H0.536133ZM1.42915 23.0211H20.0916V21.8523L10.8134 12.6968L1.42915 21.9872V23.0211ZM1.50483 2.23766L10.8134 11.4231L20.0916 2.23766V1.00893H1.50483V2.23766Z" fill="#302F38" />
</svg>


