import React from "react";
import styled from "styled-components";
import { Text12, Text16 } from "../../../Components/Text/Text";
import theme from "../../../Consts/Theme";
import { useTranslation } from "react-i18next";

// styled-components
const StyledContainer = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 10px;
  border: ${(props) => props.border || "none"};
  background: ${(props) => props.bgColor || "none"};
  width: 100%;
`;
const StyledInnerContainer = styled.div`
  position: relative;
  display: inline-block;

  svg.doodle {
    z-index: 1;
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);
  }

  div.content {
    padding: 4px 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    p {
      margin-left: 5px;
      color: ${(props) => props.color || "none"};
    }
  }
  @media (max-width: 768px) {
    svg.doodle {
      width: 76px;
      bottom: 10%;
    }
    div.content {
      padding: 2px 4px;
      p {
        margin-left: 2px;
      }

    }
    p.card-content {
      font-size: 11px;
    }
  }
`;

const StyledBadge = styled.div`
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  border-radius: 6px;
  background: linear-gradient(153deg, #4b4bff 4.23%, #7497ff 106.88%);
  padding: 3px 6px;
  font-size: 10px;
  color: ${theme.white};
  text-transform: uppercase;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 9px;
    line-height: normal;
  }

  @media (max-width: 768px) {
    top: -50%;
    left: 100%;
  transform: translateX(-100%);


    p {
      font-size: 7px;
    }
    top: -30%;
    padding: 2px 4px;
  }
`;

const FullGroup = ({ text, bgColor, border, color }) => {
  const { t } = useTranslation();
  return (
    <StyledContainer bgColor={bgColor} border={border}>
      <StyledBadge>
        <Text12 color={theme.white} fontWeight={500}>
          {t("homepage.hero_section.filled_status")}
        </Text12>
      </StyledBadge>
      <StyledInnerContainer color={color}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="109"
          height="12"
          viewBox="0 0 109 12"
          fill="none"
          className="doodle"
        >
          <path
            d="M14.7562 11.0946C39.4858 8.20382 64.4387 7.34454 89.1617 4.2937C95.077 3.56374 101.012 2.68903 106.861 1.56172C109.674 1.0196 107.634 1.15483 106.647 1.15483C71.2457 1.15483 35.9306 6.04087 1 11.0946"
            stroke="#7497FF"
            stroke-width="1.10608"
            stroke-linecap="round"
          />
        </svg>

        <div className="content">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="19"
            viewBox="0 0 15 19"
            fill="none"
          >
            <path
              d="M0.389648 15.6625V14.3384H2.15521V7.62924C2.15521 6.39335 2.51935 5.2862 3.24765 4.30779C3.97594 3.32937 4.93596 2.71511 6.12771 2.46499V1.97946C6.12771 1.61163 6.25645 1.29898 6.51393 1.0415C6.77141 0.784027 7.08406 0.655289 7.45188 0.655289C7.81971 0.655289 8.13236 0.784027 8.38984 1.0415C8.64731 1.29898 8.77605 1.61163 8.77605 1.97946V2.46499C9.9678 2.71511 10.9278 3.32937 11.6561 4.30779C12.3844 5.2862 12.7486 6.39335 12.7486 7.62924V14.3384H14.5141V15.6625H0.389648ZM7.45188 18.3109C6.96635 18.3109 6.55071 18.138 6.20496 17.7922C5.8592 17.4465 5.68632 17.0308 5.68632 16.5453H9.21744C9.21744 17.0308 9.04456 17.4465 8.69881 17.7922C8.35305 18.138 7.93741 18.3109 7.45188 18.3109Z"
              fill="#4B4BFF"
            />
          </svg>
          <Text16 className="card-content" fontWeight={500}>
            {text}
          </Text16>
        </div>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default FullGroup;
