import React from "react";
import styled from "styled-components/macro";
import theme from "../../../../Consts/Theme";

// styled-components
const StyledContainer = styled.article`
  font-family: Inter;
  border-radius: 15px;
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #484954;
  background: linear-gradient(
    148deg,
    #484954 -2.07%,
    rgba(72, 73, 84, 0.22) 49.26%,
    rgba(72, 73, 84, 0.57) 102.78%
  );

  .number {
    font-size: 80px;
    font-weight: 300;
    background: linear-gradient(148deg, #7497ff -2.07%, #c3c3d1 102.78%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.98px;
    text-transform: uppercase;
    color: ${theme.gray4};
    margin-top: 10px;
  }

  @keyframes counterAnimation {
  from {
    --counter-target: 0;
  }
  to {
    --counter-target: 100; /* Target number */
  }
}


  @media (max-width: 550px) {
    padding: 16px 0;
    .number {
      font-size: 40px;
    }
    .description {
      font-size: 10px;
      margin-top: 3px;
    }
  }
`;

const CountDownCard = ({ number, description }) => {
  return (
    <StyledContainer>
      <p className="number">{number}</p>
      <p className="description">{description}</p>
    </StyledContainer>
  );
};

export default CountDownCard;
