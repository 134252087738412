import React from "react";
import styled from "styled-components";
import { Text12, Text16 } from "../../../Components/Text/Text";
import theme from "../../../Consts/Theme";

// styled-components
const StyledContainer = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 10px;
  background: linear-gradient(115deg, #f1f1f1 8.82%, #e6e7eb 89.22%);
`;
const StyledInnerContainer = styled.div`
  position: relative;
  display: inline-block;

  svg.doodle {
    position: absolute;
    z-index: 1;
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);
  }

  div.content {
    padding: 4px 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 0;

    p {
      margin-left: 5px;
    }
  }
  @media (max-width: 768px) {
    div.content {
      
      p {
        margin-left: 2px;
      }
    }
  }
`;

const AvailableGroup = ({ text }) => {
  return (
    <StyledContainer>
      <StyledInnerContainer>
        <div className="content">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="19"
            viewBox="0 0 15 19"
            fill="none"
          >
            <path
              d="M0.389648 15.6625V14.3384H2.15521V7.62924C2.15521 6.39335 2.51935 5.2862 3.24765 4.30779C3.97594 3.32937 4.93596 2.71511 6.12771 2.46499V1.97946C6.12771 1.61163 6.25645 1.29898 6.51393 1.0415C6.77141 0.784027 7.08406 0.655289 7.45188 0.655289C7.81971 0.655289 8.13236 0.784027 8.38984 1.0415C8.64731 1.29898 8.77605 1.61163 8.77605 1.97946V2.46499C9.9678 2.71511 10.9278 3.32937 11.6561 4.30779C12.3844 5.2862 12.7486 6.39335 12.7486 7.62924V14.3384H14.5141V15.6625H0.389648ZM7.45188 18.3109C6.96635 18.3109 6.55071 18.138 6.20496 17.7922C5.8592 17.4465 5.68632 17.0308 5.68632 16.5453H9.21744C9.21744 17.0308 9.04456 17.4465 8.69881 17.7922C8.35305 18.138 7.93741 18.3109 7.45188 18.3109Z"
              fill="#4B4BFF"
            />
          </svg>
          <Text16 fontWeight={500}>{text}</Text16>
        </div>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default AvailableGroup;
