import { useEffect, useState } from "react";

const useCountdown = (targetDate) => {
  const millisecondsInOneDay = 86400000
  const countDownDate = new Date(targetDate).getTime() + millisecondsInOneDay ;
  const presentDate = new Date().getTime();

  const [countDown, setCountDown] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - presentDate);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [presentDate]);

  const getReturnValues = (countDown) => {


    // calculate time left
    let days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    if (days < 10) {
      days =  days;
    }
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }

    return [days, hours, minutes, seconds];
  };

  return getReturnValues(countDown);
};

export default useCountdown;
