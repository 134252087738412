export const xBackgroundItem = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.8 7.9L23.9 0L15.8 8L7.89999 0.0999985L0 8.1L7.89999 16L0 23.8L8 31.8L15.8 23.9L23.8 31.9L31.8 24L23.8 16L31.8 7.9Z" fill="#7497FF" />
    <path d="M31.8 7.9L23.9 0L15.8 8L7.89999 0.0999985L0 8.1L7.89999 16L0 23.8L8 31.8L15.8 23.9L23.8 31.9L31.8 24L23.8 16L31.8 7.9Z" fill="#7497FF" />
    <path d="M31.8 7.9L23.9 0L15.8 8L7.89999 0.0999985L0 8.1L7.89999 16L0 23.8L8 31.8L15.8 23.9L23.8 31.9L31.8 24L23.8 16L31.8 7.9Z" fill="#7497FF" />
    <path d="M31.8 7.9L23.9 0L15.8 8L7.89999 0.0999985L0 8.1L7.89999 16L0 23.8L8 31.8L15.8 23.9L23.8 31.9L31.8 24L23.8 16L31.8 7.9Z" fill="#7497FF" />
</svg>

export const starBackgroundItem = <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.8 18.4868L23.8 0L17.9 18.4868L0 24.5802L17.9 30.7769L23.8 49.2637L29.8 30.7769L47.7 24.5802L29.8 18.4868Z" stroke="#7497FF" />
</svg>

export const darkStarBackgroundItem = <svg width="82" height="155" viewBox="0 0 82 155" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_9451_7697" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="82" height="155">
        <rect width="82" height="155" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_9451_7697)">
        <path d="M95.5 0L96.7 41.8L131.5 18.7L111.6 55.5L153.4 52.9L117.8 74.8L155.2 93.5L113.4 94.7L136.5 129.5L99.7 109.6L102.3 151.4L80.4 115.8L61.8 153.2L60.6 111.4L25.7 134.5L45.6 97.7L3.8 100.3L39.4 78.4L2 59.8L43.8 58.6L20.7 23.7L57.5 43.6L54.9 1.8L76.8 37.4L95.5 0Z" fill="#4B4BFF" stroke="#7497FF" strokeMiterlimit="10" />
    </g>
</svg>

export const lightStarBackgroundItem = <svg width="81" height="152" viewBox="0 0 81 152" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_9451_7693" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="81" height="152">
        <rect width="81" height="152" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_9451_7693)">
        <path d="M20.4 0L21.6 41.8L56.5 18.7L36.6 55.5L78.4 52.9L42.8 74.8L80.2 93.4L38.4 94.6L61.5 129.5L24.7 109.6L27.3 151.4L5.4 115.8L-13.3 153.2L-14.5 111.4L-49.3 134.5L-29.4 97.7L-71.2 100.3L-35.6 78.4L-73 59.7L-31.2 58.5L-54.3 23.7L-17.5 43.6L-20.1 1.8L1.8 37.4L20.4 0Z" fill="#7497FF" />
    </g>
</svg>
