import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { TextCustom } from "../../../../Components/Text/Text";
import useMediaCustom from "../../../../Hooks/useMediaCustom";
import Wrapper from "../../../../Components/Wrapper/Wrapper";
import theme from "../../../../Consts/Theme";
import AwardCard from "./AwardCard";
import awardFirst from "./Static/award-1.png";
import awardSecond from "./Static/award-2.png";
import awardThird from "./Static/award-3.png";
import awardFourth from "./Static/award-4.png";
import awardFifth from "./Static/award-5.png";

// styled-components
const StyledContainer = styled.section`
  padding-top: 105px;

  @media (max-width: 767px) {
    padding-top: 56px;
  }
`;
const StyledHeader = styled.section`
  width: 70%;
  margin: 0 auto 60px;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto 48px;
    p {
      font-size: 25px;
      line-height: 40px;
    }
  }
`;
const StyledAwardsContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 16px;

  @media (max-width: 767px) {
    display: block;
  }

  @media (min-width: 767px) and (max-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const StyledLastTwoElements = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;

  article {
    width: 100%;
  }

  article img {
    width: 100px !important;
    align-self: center;
    justify-content: center;
  }

  div.img-box {
    height: 100px !important;
    align-self: center !important;
    display: flex;
    align-items: center;
    justify-self: center;
    margin-bottom: auto;
    margin-top: 40px;
  }

  @media (min-width: 376px) and (max-width: 424px) {
    width: 100px !important;
    height: 100px;
  }
`;

const awardsData = [
  {
    id: 1,
    awardPlace: "1 место",
    awardTitle: "Лаптоп МаcBook Air M1",
    image: awardFirst,
    badgeBg: "linear-gradient(140deg, #FED445 3.96%, #FFE797 96.04%)",
    badgeColor: "#100824",
  },
  {
    id: 2,
    awardPlace: "2 место",
    awardTitle: "iPhone 15",
    image: awardSecond,
    badgeBg: "linear-gradient(140deg, #E1E2E7 3.96%, #B9BBC4 96.04%)",
    badgeColor: "#100824",
  },
  {
    id: 3,
    awardPlace: "3 место",
    awardTitle: "iPad 10",
    image: awardThird,
    badgeBg: "linear-gradient(140deg, #C6A988 3.96%, #7D4503 96.04%)",
    badgeColor: "#100824",
  },
  {
    id: 4,
    awardPlace: "4 место",
    awardTitle: "Speaker Marshall",
    image: awardFourth,
    badgeBg: "linear-gradient(140deg, #D4DEFD 3.96%, #7497FF 96.04%)",
    badgeColor: "#234BDB",
  },
  {
    id: 5,
    awardPlace: "5 место",
    awardTitle: "Слушалки Marshall Major",
    image: awardFifth,
    badgeBg: "linear-gradient(140deg, #D4DEFD 3.96%, #7497FF 96.04%)",
    badgeColor: "#234BDB",
  },
];

const Awards = () => {
  const { t } = useTranslation();
  const isLaptop = useMediaCustom("(min-width: 600px)");
  const [cards, setCards] = useState(awardsData);
  const [lastTwoCards, setLastTwoCards] = useState();

  useEffect(() => {
    if (!isLaptop) {
      const lastTwoElements = awardsData.slice(-2);
      const firstThreeElements = awardsData.slice(0, 3);
      setLastTwoCards(lastTwoElements);
      setCards(firstThreeElements);
    } else {
      setCards(awardsData);
    }
  }, [isLaptop]);
  return (
    <StyledContainer>
      <Wrapper>
        <StyledHeader>
          <TextCustom
            fontSize="40"
            lineHeight="56"
            fontWeight={600}
            color={theme.darkBlack}
          >
            {t("homepage.awards.section_title")}
          </TextCustom>
        </StyledHeader>
        <StyledAwardsContainer>
          {awardsData?.map((award) => (
            <AwardCard key={award.id} {...award} />
          ))}

          {/* {!isLaptop && (
            <StyledLastTwoElements >
              {lastTwoCards?.map((award) => (
                <AwardCard key={award.id} {...award} />
              ))}
            </StyledLastTwoElements>
          )} */}
        </StyledAwardsContainer>
      </Wrapper>
    </StyledContainer>
  );
};

export default Awards;
