import { createGlobalStyle } from 'styled-components';
import '../Fonts/fonts.css';

const GlobalStyle = createGlobalStyle`
    /* Reset styles */
    *:not(input) {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
    }

    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline; 
    }

    /* HTML5 display-role reset for older browsers */

    article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        display: block; 
    }

    body {
        line-height: 1; 
        // font-family: 'Ubuntu', sans-serif;
    }
    ol, ul {
        list-style: none; 
    }

    blockquote, q {
        quotes: none; 
    }

    blockquote {
        &:before, &:after {
            content: '';
            content: none; 
        } 
    }

    q {
        &:before, &:after {
            content: '';
            content: none; 
        } 
    }

    table {
        border-collapse: collapse;
        border-spacing: 0; 
    }

    * {
        box-sizing: border-box;
    }
    a {
        text-decoration: none;
        outline: none;
        color: unset;
    }

    em {
        font-style: italic;
    }

    strong {
        font-weight: bold;
    }
    
    button {
        :focus, :visited, :active {
            outline: none;
            box-shadow: none;
        }
    }
    .homepage-main{
        position: relative;
        z-index: 1;
    }
    &::-webkit-scrollbar {
        width: 10px;
        background: #E6E7EB;
    }

    &::-webkit-scrollbar-track {
        background-color: ${props => props.theme.gray2};
    }

    &::-webkit-scrollbar-thumb {
        background: ${props => props.theme.darkGray};
        border-radius: 4px;
    }
	
    &::-webkit-scrollbar-thumb:hover {
        background: ${props => props.theme.yellow};
        border-radius: 4px;
    }
    .i-want-to-know-more-cta-container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &>svg:first-child{
            width: 95%;
            transition: transform .1s linear;
            &:hover{
                transform: rotate(-5deg);
                cursor: url("data:image/svg+xml,%3Csvg width='30' height='51' viewBox='0 0 30 51' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 6H3V39H6V36H9V33H12V36H15V42H18V48H24V42H21V36H18V30H27V27H24V24H21V21H18V18H15V15H12V12H9V9H6V6Z' fill='white'/%3E%3Cpath d='M0 0H3V3H6V6H3V39H6V42H0V0Z' fill='black'/%3E%3Cpath d='M9 36V39H6V36H9Z' fill='black'/%3E%3Cpath d='M12 36H9V33H12V36Z' fill='black'/%3E%3Cpath d='M15 42H12V36H15V42Z' fill='black'/%3E%3Cpath d='M18 48H15V42H18V48Z' fill='black'/%3E%3Cpath d='M24 48H18V51H24V48Z' fill='black'/%3E%3Cpath d='M24 42H27V48H24V42Z' fill='black'/%3E%3Cpath d='M21 36H24V42H21V36Z' fill='black'/%3E%3Cpath d='M27 27V30H18V36H21V33H30V27H27Z' fill='black'/%3E%3Cpath d='M24 24H27V27H24V24Z' fill='black'/%3E%3Cpath d='M21 21H24V24H21V21Z' fill='black'/%3E%3Cpath d='M18 18H21V21H18V18Z' fill='black'/%3E%3Cpath d='M15 15H18V18H15V15Z' fill='black'/%3E%3Cpath d='M12 12H15V15H12V12Z' fill='black'/%3E%3Cpath d='M9 9H12V12H9V9Z' fill='black'/%3E%3Cpath d='M9 9V6H6V9H9Z' fill='black'/%3E%3C/svg%3E") 16 0, auto;
            }
        }
    }
`

export default GlobalStyle;