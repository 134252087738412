import React, { useContext, useRef, useState } from "react";

//npm imports
import styled, { withTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

//component imports
import {
  Text16,
  Text18,
  HeaderMedium28,
  HeaderLarge40,
  Header32,
} from "../../Components/Text/Text";


//other imports
import { GlobalContext } from "../../Context/GlobalContext";
import { API } from "../../Consts/Api";
import Footer from "../../Components/Footer/Footer";
import { useEffect } from "react";
import BrainsterNextLogo from "../../Components/BrainsterNextLogo/BrainsterNextLogo";
import theme from "../../Consts/Theme";
import heroDesktop from "./Static/heroDesktop.webp";
import heroMobile from "./Static/heroMobile.webp";
import seiImage from "./Static/seiImage.svg";
import guxImage from "./Static/guxImage.svg";
import tapeOne from "./Static/tapeOne.svg";
import tapeTwo from "./Static/tapeTwo.svg";
import tapeOneMobile from "./Static/tapeOneMobile.svg";
import tapeTwoMobile from "./Static/tapeTwoMobile.svg";
import FullGroup from "./Blocks/FullGroup";
import AvailableGroup from "./Blocks/AvailableGroup";
import ButtonBasic from "../../Components/ButtonBasic/ButtonBasic";
import useMediaCustom from "../../Hooks/useMediaCustom";
import "./BodyStyle.css";

//styled-components
const StyledAwardsPending = styled.section`
  width: 100%;
  min-height: 100vh;
  background-color: ${(props) => props.theme.gray6};
  position: relative;



  img.tape-one-desktop,
  img.tape-two-desktop {
    position: absolute;
    display: block;
    width: 100%;
  }

  img.tape-one-mobile,
  img.tape-two-mobile {
    display: none;
  }

  img.tape-one-desktop {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    /* z-index: 2; */
    z-index: ${(props) => (props.showPopup ? "0" : "2")};
  }
  img.tape-two-desktop {
    left: 0;
    top: 53%;
    /* z-index: 1; */
    z-index: ${(props) => (props.showPopup ? "0" : "1")};
  }

  @media (max-width: 768px) {
    background-color: ${(props) => props.theme.violet};
    img.tape-one-mobile,
    img.tape-two-mobile {
      position: absolute;
      display: block;
      width: 100%;
    }
    img.tape-one-mobile {
      left: 0;
      top: 48%;
    }
    img.tape-two-mobile {
      left: 0;
      top: 53%;
    }

    img.tape-one-desktop,
    img.tape-two-desktop {
      display: none;
    }
  }
`;

const StyledHeroSection = styled.section`
  background-image: url(${heroDesktop});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 570px;

  @media (max-width: 768px) {
    background-image: url(${heroMobile});
  }
`;
const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  padding-top: 180px;

  div.heading-wrap {
    text-align: center;
  }
  div.heading-wrap h4 {
    font-size: 32px;
  }
  div.heading-wrap h4.title-desktop {
    display: block;
  }

  div.heading-wrap h4.title-mobile {
    display: none;
  }

  @media (max-width: 768px) {
    padding-top: 100px;
    div.heading-wrap h4.title-desktop {
      display: none;
    }
    div.heading-wrap h4.title-mobile {
      display: block;
      line-height: normal;
    }
    div.heading-wrap h4 {
      font-size: 32px;
      margin-top: 20px;
    }
  }
`;

const StyledCard = styled.article`
  width: 80%;
  border-radius: 20px;
  background: #f9f9f9;
  margin: 35px 0 0 0;
  max-width: 1050px;
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 70px 0;
  z-index: ${(props) => (props.showPopup ? "0" : "3")};

  @media (max-width: 768px) {
    width: 95%;
    padding: 60px 0 50px;
  }
`;

const StyledInnerCardWrap = styled.div`
  text-align: center;

  div.group-status {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.group-status > div {
    width: 100px;
  }

  div.group-status > div:not(:last-child) {
    margin-right: 16px;
  }

  div.title {
    margin-top: 30px;
    margin-bottom: 40px;
    .mt-0 {
      margin-top: 0 !important;
    }
    a {
      text-decoration: underline;
    }
  }
  div.title h4.main-heading-desktop {
    display: block;
  }
  div.title h4.main-heading-mobile {
    display: none;
  }
  div.title p.copy-text-desktop {
    display: block;
    margin-top: 30px;
  }
  div.title p.copy-text-mobile {
    display: none;
  }
  div.cta-button button {
    background-color: ${theme.violet};
    padding: 14px 20px;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    div.title h4 {
      font-size: 28px;
    }
    div.title h4.main-heading-desktop {
      display: none;
    }
    div.title h4.main-heading-mobile {
      display: block;
    }
    div.group-status > div:not(:last-child) {
      margin-right: 4px;
      /* padding: 5px; */
    }
    div.group-status > div {
      padding: 5px;
      width: auto;
    }
    div.group-status {
      display: flex;
    }
    div.cta-button button p {
      font-weight: 600;
    }
    div.title p.copy-text-desktop {
      display: none;
    }
    div.title p.copy-text-mobile {
      display: block;
      margin-top: 20px;
    }
  }
`;

const StyledSectionFooter = styled.footer`
  padding-top: 15%;

  text-align: center;

  div.button-container {
    margin-top: 30px;
    padding-bottom: 80px;
  }

  div.button-container button {
    width: 372px;
    text-align: left;
  }

  button.academy-button {
    border-radius: 65.526px;
    border: 0.728px solid var(--black, #302f38);
    background-color: ${theme.violet};
    outline: none;
    cursor: pointer;
    padding: 20px 30px;
    margin-right: 16px;
    display: inline-flex;
    align-items: center;
  }
  button.academy-button span {
    margin-left: 15px;
    color: ${theme.white};
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  @media (max-width: 768px) {
    padding: 100% 16px 0;
    width: 100%;

    p.copy-text {
      font-size: 22px;
      color: ${theme.white} !important;
    }
    div.button-container {
      width: 100%;
    }
    div.button-container a {
      display: inline-block;
      width: 100%;
    }

    div.button-container a button {
      width: 100%;
      text-align: left;
    }
    div.button-container a:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

const StyledPopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  display: ${(props) => (props.showPopup ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .close-modal span {
    width: 40px;
    height: 40px;
    border: 3px solid white;
    border-radius: 50%;
    color: ${theme.white};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 30px;
  }
`;
const StyledPopup = styled.div`
  max-width: 793px;
  padding: 40px;
  background-color: ${(props) => props.theme.white};
  border-radius: 8px;
  position: relative;


  .close-modal,
  .button-box {
    display: flex;
    justify-content: flex-end;
  }

  .label {
    text-align: center;
    margin-bottom: 16px;
  }
  .button-box {
    margin-top: 20px;
  }
  .button-box button {
    background-color: ${theme.violet};
  }
`;
const IframeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  iframe {
    width: 780px;
    height: 400px;
    overflow: hidden;
    border: none;
    border-radius: 14px;
    box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    iframe #picture {
      display: none !important;
    }
  }
`;

const StyledCustomSelect = styled.select`
  display: block;
  width: 100%;
  border-color: ${theme.textGrey};
  min-height: 35px;
  border-radius: 3px;
  padding: ${(props) => (props.widthAuto ? "4px" : "16px 30px 16px 16px")};
  margin: 5px 0;
  border-radius: 4px;
  color: ${(props) => props.theme.textGrey};
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  outline: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  box-sizing: border-box;

  option {
    box-sizing: inherit;
    font-weight: normal;
    display: block;
    white-space: nowrap;
    min-height: 1.2em;
    padding: 0px 2px 1px;
    color: ${(props) => props.theme.textGrey};
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }

  @media (max-width: 768px) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
`;
const salesManagoLink = [
  {
    program: "SEI",
    link: "https://app3.salesmanago.com/cf/4ok5u4iwtjeesaps/MKD_NEXT_Software_Engineering.htm",
  },
  {
    program: "GUX",
    link: "https://app3.salesmanago.com/cf/4ok5u4iwtjeesaps/MKD_NEXT_GUX.htm",
  },
];

const defaultSelectedOption = salesManagoLink[0].link;
export const collegeGroupStatus = {
  desktop: [
    {
      name: "Група 1",
      status: "full",
    },
    {
      name: "Група 2",
      status: "full",
    },
    {
      name: "Група 3",
      status: "full",
    },
    {
      name: "Група 4",
      status: "full",
    },
  ],
  mobile: [
    {
      name: "Група 1 & 2",
      status: "full",
    },
    {
      name: "Група 3 & 4",
      status: "full",
    },
  ],
};

//main component
const AwardsPending = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const isLaptop = useMediaCustom("(min-width: 992px)");
  const popupRef = useRef();
  const iframeref = useRef();
  const { academies } = useContext(GlobalContext);
  const [showPopup, setShowPopup] = useState(false);
  const [showProgramApplicationForm, setShowProgramApplicationForm] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultSelectedOption);

  const groupStatuses = collegeGroupStatus?.[isLaptop ? "desktop" : "mobile"];

  const collegePrograms = academies?.map((academy, index) => ({
    id: academy.id,
    name: academy.name,
    link: salesManagoLink[index]?.link,
  }));

  const handleChange = (link) => {
    setSelectedOption(link);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (popupRef?.current && !popupRef?.current?.contains(event.target)) ||
        (iframeref?.current && !iframeref?.current?.contains(event.target))
      ) {
        setShowPopup(false);
        setShowProgramApplicationForm(false);
        setSelectedOption(defaultSelectedOption);
      }
    };
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, [popupRef, iframeref]);

  useEffect(() => {
    if (showPopup) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [showPopup]);

  const ctaAcademies = [
    {
      id: 1,
      link: API.createExternalLink("softwareCollege"),
      title: t("collegeAcademies.sei"),
      image: seiImage,
    },
    {
      id: 2,
      link: API.createExternalLink("graphicDesign"),
      title: t("collegeAcademies.gux"),
      image: guxImage,
    },
  ];

  return (
    <>
      <StyledAwardsPending showPopup={showPopup}>
        <img src={tapeOne} className="tape tape-one-desktop" />
        <img src={tapeTwo} className="tape tape-two-desktop" />

        <img src={tapeOneMobile} className="tape tape-one-mobile" />
        <img src={tapeTwoMobile} className="tape tape-two-mobile" />

        <StyledHeroSection>
          <StyledHeader>
            <div className="heading-wrap">
              <BrainsterNextLogo color={theme.white} />
              {/* ---------- desktop ----------- */}
              <HeaderLarge40 color={theme.white} className="title-desktop">
                {t("awards_pending.header.title")}
              </HeaderLarge40>
              {/* ---------- mobile ----------- */}
              <HeaderLarge40 color={theme.white} className="title-mobile">
                {t("awards_pending.header.title_mobile_one")} <br />
                {t("awards_pending.header.title_mobile_two")}
              </HeaderLarge40>
            </div>
          </StyledHeader>
          {/* ----------- waiting list info ------------- */}
          <StyledCard showPopup={showPopup}>
            <StyledInnerCardWrap>
              <div className="group-status">
                {groupStatuses?.map((group) => {
                  if (group.status === "full") {
                    return (
                      <FullGroup
                        text={group.name}
                        bgColor="linear-gradient(115deg, #f1f1f1 8.82%, #e6e7eb 89.22%)"
                      />
                    );
                  } else {
                    return <AvailableGroup text={group.name} />;
                  }
                })}
              </div>
              {/* ----------- title --------- */}
              <div className="title">
                <Header32
                  fontWeight={800}
                  color={theme.lightBlack}
                  className="main-heading-desktop"
                >
                  {t("awards_pending.mainHeading_part_one")} <br />
                  {t("awards_pending.mainHeading_part_two")}
                </Header32>
                <HeaderMedium28
                  fontWeight={800}
                  color={theme.lightBlack}
                  className="main-heading-mobile"
                >
                  {t("awards_pending.mainHeading_part_one_mobile")} <br />
                  {t("awards_pending.mainHeading_part_two_mobile")} <br />
                  {t("awards_pending.mainHeading_part_three_mobile")} <br />
                </HeaderMedium28>
                <Text16
                  className="copy-text-desktop "
                  fontWeight={600}
                  color={theme.lightBlack}
                >
                  {t("awards_pending.cta.copy_part_one")}
                </Text16>
                <Text16
                  className="copy-text-desktop mt-0"
                  fontWeight={600}
                  color={theme.lightBlack}
                  dangerouslySetInnerHTML={{
                    __html: t("awards_pending.cta.copy_part_two"),
                  }}
                ></Text16>
                <Text16
                  className="copy-text-mobile"
                  fontWeight={600}
                  color={theme.lightBlack}
                >
                  {t("awards_pending.cta.copy_part_one_mobile")}

                </Text16>
                <Text16
                  className="copy-text-mobile mt-0"
                  fontWeight={600}
                  color={theme.lightBlack}
                  dangerouslySetInnerHTML={{
                    __html: t("awards_pending.cta.copy_part_two_mobile"),
                  }}
                ></Text16>
              </div>
              {/* ----------- waiting list button --------- */}
              <div className="cta-button">
                <ButtonBasic
                  onClick={() => setShowPopup(true)}
                  variation="small"
                  text={t("awards_pending.cta.cta_button")}
                />
              </div>
            </StyledInnerCardWrap>
          </StyledCard>
        </StyledHeroSection>
        <StyledSectionFooter>
          <Text18 className="copy-text" fontWeight={700}>
            {t("awards_pending.more_info")}
          </Text18>
          <div className="button-container">
            {ctaAcademies.map((academy) => (
              <React.Fragment key={academy.id}>
                <a href={academy.link} target="_blank">
                  <button className="academy-button">
                    <img src={academy.image} alt="" />
                    <span> {academy.title}</span>
                  </button>
                </a>
              </React.Fragment>
            ))}
          </div>
        </StyledSectionFooter>
      </StyledAwardsPending>
      <Footer />

      {/* -------- choose program --------- */}
      <StyledPopupContainer showPopup={showPopup}>
        {!showProgramApplicationForm ? (
          <StyledPopup ref={popupRef}>
            <div className="popup-content">
              <Text18 className="label" fontWeight={700}>
                {t("application_closed.choose_program")}
              </Text18>

              <StyledCustomSelect
                onChange={(e) => handleChange(e.target.value)}
              >
                {collegePrograms?.map((program) => (
                  <option key={program.id} value={program.link}>
                    {program.name}
                  </option>
                ))}
              </StyledCustomSelect>

              <div className="button-box">
                <ButtonBasic
                  onClick={() => setShowProgramApplicationForm(true)}
                  variation="small"
                  text="Понатаму"
                />
              </div>
            </div>
          </StyledPopup>
        ) : (
          <IframeContainer>
            <iframe
              ref={iframeref}
              src={selectedOption}
              frameborder="0"
            ></iframe>
          </IframeContainer>
        )}
      </StyledPopupContainer>
    </>
  );
};

export default withTheme(AwardsPending);
