
//main component
const BrainsterNextLogo = ({ color, className }) => {

    return (
        <svg className={className} width="145" height="13" viewBox="0 0 145 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.9608 6.02145C8.84196 5.95005 8.84196 5.83104 8.93703 5.75963C9.65002 5.2598 10.1016 4.52195 10.1016 3.45088C10.1016 1.59435 8.8182 0.213856 6.29897 0.213856H0.856494C0.666364 0.213856 0.5 0.380467 0.5 0.57088V11.5672C0.5 11.7576 0.666364 11.9243 0.856494 11.9243H6.82183C9.19846 11.9243 10.6244 10.5676 10.6244 8.61583C10.6244 7.35434 9.98275 6.49748 8.9608 6.02145ZM3.49455 3.23666C3.49455 3.07005 3.63715 2.92724 3.80351 2.92724H6.13261C6.75053 2.92724 7.08326 3.30807 7.08326 3.8555C7.08326 4.40294 6.7743 4.78377 5.96624 4.78377H3.80351C3.63715 4.78377 3.49455 4.64096 3.49455 4.47435V3.23666ZM6.4891 9.21087H3.80351C3.63715 9.21087 3.49455 9.06806 3.49455 8.90145V7.44955C3.49455 7.28294 3.63715 7.14013 3.80351 7.14013H6.51287C7.32092 7.14013 7.62988 7.54475 7.62988 8.13979C7.60612 8.75864 7.29716 9.21087 6.4891 9.21087Z" fill={color} />
            <path d="M20.6297 8.63963C20.5346 8.49682 20.5584 8.33021 20.7248 8.2588C22.127 7.49715 22.7687 6.04525 22.7687 4.49815C22.7687 2.16559 21.3902 0.213856 18.3957 0.213856H13.0245C12.8344 0.213856 12.668 0.380467 12.668 0.57088V11.5672C12.668 11.7576 12.8344 11.9243 13.0245 11.9243H15.2823C15.4724 11.9243 15.6388 11.7576 15.6388 11.5672V9.09186C15.6388 8.92525 15.7814 8.78244 15.9477 8.78244H16.8746C17.0648 8.78244 17.2549 8.87765 17.3499 9.02046L19.18 11.6148C19.3226 11.8052 19.5364 11.9243 19.7503 11.9243H22.6023C22.7449 11.9243 22.84 11.7576 22.7449 11.6148L20.6297 8.63963ZM18.2293 6.06905H15.9715C15.8051 6.06905 15.6625 5.92624 15.6625 5.75963V3.23666C15.6625 3.07005 15.8051 2.92724 15.9715 2.92724H18.2293C19.4176 2.92724 19.8692 3.59369 19.8692 4.49815C19.8692 5.3312 19.4176 6.06905 18.2293 6.06905Z" fill={color} />
            <path d="M31.6341 0.547079C31.5628 0.356666 31.3489 0.213856 31.135 0.213856H28.8772C28.6634 0.213856 28.4495 0.356666 28.3782 0.547079L23.9101 11.591C23.8388 11.7338 23.9576 11.9243 24.124 11.9243H26.667C26.8809 11.9243 27.071 11.7814 27.1423 11.591L27.5701 10.4962C27.6414 10.3295 27.784 10.2105 27.9741 10.2105H31.9906C32.1808 10.2105 32.3471 10.3295 32.3947 10.4962L32.8225 11.591C32.8938 11.7814 33.0839 11.9243 33.2978 11.9243H35.8408C36.0309 11.9243 36.126 11.7338 36.0547 11.591L31.6341 0.547079ZM30.9687 7.49715H28.9961C28.8535 7.49715 28.7584 7.35434 28.8059 7.21153L29.8754 4.35534C29.8992 4.26013 30.0656 4.26013 30.0893 4.35534L31.1588 7.21153C31.2301 7.33054 31.135 7.49715 30.9687 7.49715Z" fill={color} />
            <path d="M40.4987 0.213856H38.2171C38.027 0.213856 37.8606 0.380467 37.8606 0.57088V11.5672C37.8606 11.7576 38.027 11.9243 38.2171 11.9243H40.4987C40.6888 11.9243 40.8551 11.7576 40.8551 11.5672V0.57088C40.8551 0.380467 40.6888 0.213856 40.4987 0.213856Z" fill={color} />
            <path d="M53.784 0.213856H51.5262C51.3361 0.213856 51.1697 0.380467 51.1697 0.57088V6.44988C51.1697 6.54509 51.0509 6.59269 50.9796 6.52129L46.1788 0.499474C46.0362 0.309061 45.8223 0.213856 45.5847 0.213856H43.7784C43.5883 0.213856 43.4219 0.380467 43.4219 0.57088V11.5672C43.4219 11.7576 43.5883 11.9243 43.7784 11.9243H46.0362C46.2264 11.9243 46.3927 11.7576 46.3927 11.5672V5.68823C46.3927 5.59302 46.5115 5.54542 46.5828 5.61682L51.4074 11.6148C51.55 11.8052 51.7639 11.9005 52.0016 11.9005H53.8078C53.9979 11.9005 54.1643 11.7338 54.1643 11.5434V0.57088C54.1405 0.380467 53.9742 0.213856 53.784 0.213856Z" fill={color} />
            <path d="M61.4604 4.56991C60.0582 4.09388 59.5353 3.85586 59.5353 3.37983C59.5353 2.999 59.9394 2.59438 60.9613 2.59438C61.9595 2.59438 62.5299 3.04661 62.6962 3.54644C62.7913 3.80826 62.9101 3.95107 63.1953 3.95107H65.2868C65.5007 3.95107 65.6908 3.73685 65.667 3.52264C65.5244 1.4281 63.6231 0 60.89 0C58.2282 0 56.3269 1.4043 56.3269 3.64165C56.3269 6.06941 58.7748 6.95007 60.6286 7.56891C62.0308 8.04494 62.6249 8.35436 62.6249 8.8542C62.6249 9.21122 62.2684 9.56824 61.1514 9.56824C60.1057 9.56824 59.4878 9.21122 59.2739 8.80659C59.0838 8.49717 59.0125 8.42577 58.6797 8.42577H56.6596C56.4457 8.42577 56.2556 8.63998 56.2793 8.8542C56.4932 10.9963 58.5847 12.1626 61.2227 12.1626C63.8846 12.1626 65.8809 10.9725 65.8809 8.68759C65.8334 6.09321 63.0765 5.14115 61.4604 4.56991Z" fill={color} />
            <path d="M77.1225 0.213856H67.5685C67.3784 0.213856 67.212 0.380467 67.212 0.57088V2.76063C67.212 2.95104 67.3784 3.11765 67.5685 3.11765H70.563C70.7294 3.11765 70.872 3.26046 70.872 3.42707V11.5672C70.872 11.7576 71.0384 11.9243 71.2285 11.9243H73.5101C73.7002 11.9243 73.8665 11.7576 73.8665 11.5672V3.42707C73.8665 3.26046 74.0091 3.11765 74.1755 3.11765H77.1701C77.3602 3.11765 77.5266 2.95104 77.5266 2.76063V0.57088C77.5028 0.380467 77.3364 0.213856 77.1225 0.213856Z" fill={color} />
            <path d="M87.9364 0.213856H79.6657C79.4756 0.213856 79.3092 0.380467 79.3092 0.57088V11.5672C79.3092 11.7576 79.4756 11.9243 79.6657 11.9243H87.9364C88.1265 11.9243 88.2928 11.7576 88.2928 11.5672V9.40128C88.2928 9.21087 88.1265 9.04426 87.9364 9.04426H82.6127C82.4464 9.04426 82.3038 8.90145 82.3038 8.73483V7.83037C82.3038 7.66376 82.4464 7.52095 82.6127 7.52095H86.8669C87.057 7.52095 87.2234 7.35434 87.2234 7.16393V5.16459C87.2234 4.97418 87.057 4.80757 86.8669 4.80757H82.6127C82.4464 4.80757 82.3038 4.66476 82.3038 4.49815V3.42707C82.3038 3.26046 82.4464 3.11765 82.6127 3.11765H87.9364C88.1265 3.11765 88.2928 2.95104 88.2928 2.76063V0.57088C88.2928 0.380467 88.1265 0.213856 87.9364 0.213856Z" fill={color} />
            <path d="M100.604 11.6149L98.465 8.61586C98.3699 8.47305 98.3936 8.30644 98.56 8.23503C99.9622 7.47338 100.604 6.02149 100.604 4.47438C100.604 2.14182 99.2255 0.190094 96.2309 0.190094H90.8835C90.6934 0.190094 90.527 0.356705 90.527 0.547118V11.5435C90.527 11.7339 90.6934 11.9005 90.8835 11.9005H93.1413C93.3314 11.9005 93.4978 11.7339 93.4978 11.5435V9.06809C93.4978 8.90148 93.6404 8.75867 93.8068 8.75867H94.7336C94.9238 8.75867 95.1139 8.85388 95.209 8.99669L97.039 11.5911C97.1816 11.7815 97.3955 11.9005 97.6094 11.9005H100.461C100.604 11.9243 100.699 11.7577 100.604 11.6149ZM96.0646 6.06909H93.783C93.6166 6.06909 93.474 5.92628 93.474 5.75967V3.2367C93.474 3.07009 93.6166 2.92728 93.783 2.92728H96.0408C97.2291 2.92728 97.6807 3.59372 97.6807 4.49818C97.7044 5.33124 97.2529 6.06909 96.0646 6.06909Z" fill={color} />
            <path d="M112.748 0.262043V11.9724H110.3L104.668 0.833282V11.9724H103.266V0.262043H105.713L111.346 11.4012V0.262043H112.748Z" fill={color} />
            <path d="M116.551 1.45212V5.26038H122.254V6.45046H116.551V10.7823H122.92V11.9724H115.077V0.262043H122.92V1.45212H116.551Z" fill={color} />
            <path d="M130.668 5.92687L134.233 11.9487H132.593L129.385 6.30769L125.986 11.9487H124.442L128.102 5.92687L124.608 0.238281H126.248L129.409 5.54604L132.522 0.238281H134.114L130.668 5.92687Z" fill={color} />
            <path d="M144.5 1.52353H140.84V11.9486H139.366V1.52353H135.73V0.262043H144.5V1.52353Z" fill={color} />
        </svg>

    )
}

export default BrainsterNextLogo;