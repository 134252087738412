//npm imports
import styled from "styled-components";
import { Text18 } from "../Text/Text";

//styled components
export const StyledAnswerButton = styled.button`
    width: 100%;
    height: max-content;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;    
    box-sizing: border-box; 
    border: none;
    border-radius: 4px;
    box-shadow: ${props => props.active && `3px 3px 17px rgba(0, 0, 0, 0.15)`}!important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    .button-container{
        width: 100%;
        height: 100%;
        padding: 21px 21px 19px 21px;
        background-color: ${props => props.active ? props.theme.violet : props.theme.veryLightGray};
        border-radius: 4px;
        display: flex;
        justify-content: flex-start;
        align-items: ${props => !props.isImageAnswer && "center"};
        .button-circle{
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: ${props => props.active ? props.theme.altBlue : props.theme.white};
            margin-right: 16px;
        }
        &>p{
            width: ${props => props.isImageAnswer ? "100%" : "calc(100% - 16px)"};
            text-align: left;
        }
    }
    &:hover{
        cursor: pointer;

        .button-circle{
            background-color: ${props => props.active ? props.theme.altBlue : props.theme.violet}
        }
        .button-container{
            background-color: ${props => props.active ? props.theme.violet : props.theme.altBlue};
        }
    }
`

//main component
const AnswerButton = ({ text, onClick, active, isImageAnswer }) => {
    function createMarkup(text) {
        return { __html: text };
    }
    return (
        <StyledAnswerButton onClick={onClick} active={active} isImageAnswer={isImageAnswer}>
            <div className="button-container">
                <div className="button-circle"></div>
                <Text18 dangerouslySetInnerHTML={createMarkup(text)}></Text18>
            </div>
        </StyledAnswerButton>
    )
}

export default AnswerButton;