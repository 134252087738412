import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";

//npm imports
import { useLocation, useNavigate } from "react-router";

//other imports
import { API } from "../Consts/Api";

export const GlobalContext = createContext({});

const { Provider } = GlobalContext;

export const GlobalContextProvider = (props) => {
  const user = useMemo(
    () => ({
      name: "",
      email: "",
      highschool: "",
      birthDate: {
        day: null,
        month: null,
        year: null,
      },
      academyId: "",
      token: "",
      referred_by: null,
    }),
    []
  );
  const [userInfo, setUserInfo] = useState(user);
  const [academies, setAcademies] = useState([]);
  const [termsAgreement, setTermsAgreement] = useState(true);

  const [allQuestions, setAllQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [nextQuestion, setNextQuestion] = useState(null);
  const [duration, setDuration] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0); //used in setBodyUnscrollable to store original scroll position value
  const [showAllRulesPopup, setShowAllRulesPopup] = useState({
    display: false,
    privacy: false,
  });

  const [term, setTerm] = useState({});
  let expirationDate = new Date(term.expiration_date);
  let resultsDate = new Date(term.results_date);

  let navigate = useNavigate();
  let location = useLocation();

  const setBodyUnscrollable = useCallback(
    (value) => {
      let scrollHeight = window.scrollY;
      if (value) {
        //when value entered is true we set the body to be unscrollable
        let bodyWidth = document.body.offsetWidth; //the width of the body is different than width of the window due to scrollbar width
        document.body.style.position = "fixed";
        document.body.style.width = `${bodyWidth}px`; //scrollbar width will be eliminated by setting body's scroll position to fixed so we need to set body's width to it's previus width when scroll bar was present
        document.body.style.height = "100vh";
        document.body.style.overflowY = "hidden";
        document.body.style.top = `-${scrollHeight}px`;
        setScrollPosition(scrollHeight); //store the scroll position value in the hook for later when body gets back to original properties
      } else {
        //when that value is toggled to false we set body to be scrollable again
        document.body.style.position = "";
        document.body.style.width = "";
        document.body.style.height = "";
        document.body.style.overflowY = "";
        document.body.style.top = "";
        window.scrollTo(0, scrollPosition); //scroll body to stored position from before body was set to unscrollable
        setScrollPosition(0); //return original value to scrollPositionhook
      }
    },
    [setScrollPosition, scrollPosition]
  );

  const checkIfClickedOutside = useCallback((e, ref, callback) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback(false);
    }
  }, []);

  useEffect(() => {
    API.axios
      .get(API.createApiRoute("academies"))
      .then((response) => setAcademies(response.data.data))
      .catch((error) => console.error(error));
    let guid = localStorage.getItem("guid");
    API.axios
      .get(API.createApiRoute("term"))
      .then((response) => setTerm(response.data.data))
      .catch((error) => console.error(error));
    if (guid !== null) {
      API.axios
        .get(API.createApiRoute("user"))
        .then((response) => {
          if (response.data.success) {
            setUserInfo({
              ...userInfo,
              name: response.data.data.name,
              surname: response.data.data.surname,
              email: response.data.data.email,
              academy_id: response.data.data.academy_id || null,
              token: response.data.data.token,
              birthDate: {
                ...userInfo.birthDate,
                day: parseInt(response.data.data.birth_date_parts?.day),
                month: parseInt(response.data.data.birth_date_parts?.month),
                year: parseInt(response.data.data.birth_date_parts?.year),
              },
            });

            if (response.data.data.academy_id) {
              startAssessment(response.data.data.academy_id);
            }
          }
        })
        .catch((error) => console.error(error.response));
    }
    //eslint-disable-next-line
  }, []);

  const checkDatesForDisplayingAwards = (dates, today) => {
    if (
      dates.resultsDate &&
      dates.resultsDate.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0)
    ) {
      return true;
    } else if (
      dates.endDate &&
      dates.endDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)
    ) {
      return true;
    }

    return false;
  };
  useEffect(() => {
    //if term has passed (term.end_date < today)
    if (
      term.end_date &&
      term.results_date &&
      academies.length > 0 &&
      checkDatesForDisplayingAwards(
        { endDate: new Date(term.end_date) },
        new Date()
      )
    ) {
      //if results date is today or already passed
      if (
        term.results_date &&
        checkDatesForDisplayingAwards(
          { resultsDate: new Date(term.results_date) },
          new Date()
        )
      ) {
        //if user is already on awards page then return nothing
        // if (location.pathname.indexOf("leaderboard") > -1) {
        //     return;
        // }
        navigate(API.createRoute("awardsPending"));

        //if not on awards page, navigate user to awards page
        // navigate(API.createRoute("awards", { ACADEMY_ID: academies[0]?.id }))
      } else {
        // if result date has not come yet but term has passed, navigate user to /pending AwardsPending page
        navigate(API.createRoute("awardsPending"));
      }
    } else {
      //if term is active , prevent user from navigating to pending page or Google search result taking user to pending page
      if (location.pathname.indexOf("pending") > -1) {
        navigate(API.createRoute("home"));
      }
    }
  }, [
    term.end_date,
    term.results_date,
    academies,
    location.pathname,
    navigate,
  ]);

  const checkIfAssessingUserIsLeaving = useCallback(() => {
    if (
      location.pathname !== "/" &&
      location.pathname.startsWith("/reffer") === false
    ) {
      //then maybe they also are not on Ongoing page
      if (location.pathname.indexOf(API.createRoute("ongoing")) < 0) {
        //and if that page is not Assessment
        if (
          location.pathname !== API.createRoute("assessment") &&
          localStorage.getItem("guid") !== null
        ) {
          //then he is on a page other than Homepage, Ongoing and Assessment
          //and that is forbidden when Assessment has started
          //navigate user to Ongoing page and get Assessment data from backend
          navigate(API.createRoute("ongoing"));
        }
      }
    }
  }, [navigate, location.pathname]);

  const formatExpirationDate = () => {
    return `${
      expirationDate.getDate() < 9 ? "0" : ""
    }${expirationDate.getDate()}.${expirationDate.getMonth() < 8 ? "0" : ""}${
      expirationDate.getMonth() + 1
    }.${expirationDate.getFullYear()}`;
  };
  //temporary solution -- to be revised
  const formatResultsDate = () => {
    return `${resultsDate.getDate() < 9 ? "0" : ""}${resultsDate.getDate()}.${
      resultsDate.getMonth() < 8 ? "0" : ""
    }${resultsDate.getMonth() + 1}.${resultsDate.getFullYear()}`;
  };

  //A popstate event is dispatched to the window each time the active history entry changes between two history entries for the same document.
  window.onpopstate = () => {
    if (userInfo.academy_id) {
      checkIfAssessingUserIsLeaving();
    }
  };

  useEffect(() => {
    if (currentQuestion.id) {
      for (let i = 0; i < allQuestions.length; i++) {
        if (allQuestions[i].id === currentQuestion.id) {
          setNextQuestion({ ...allQuestions[i + 1], index: i + 1 });
          return;
        }
      }
    }
    //eslint-disable-next-line
  }, [currentQuestion.id]);

  const startAssessment = (currentSelectedAcademy) => {
    API.axios
      .get(API.createApiRoute("assessment") + currentSelectedAcademy + "/")
      .then((response) => {
        if (response.data.success) {
          setUserInfo({
            ...userInfo,
            academy_id: currentSelectedAcademy,
            academyId: currentSelectedAcademy,
          });
          let questions = response.data.data.questions;
          let duration = response.data.data.duration;
          setAllQuestions(questions);
          setDuration(duration);
          if (questions?.length > 0) {
            for (let i = 0; i < questions.length; i++) {
              if (questions[i].answer_id === null) {
                setCurrentQuestion({ ...questions[i], index: i.toString() });
                return;
              }
            }
          }
        }
      })
      .catch((error) => console.error(error.response));
  };
  const onTimeOutNavigateUserToResult = useCallback(() => {
    let timeout = setTimeout(() => {
      let guid = localStorage.getItem("guid");
      if (guid) {
        navigate(API.createRoute("result", { GUID: guid }));
        localStorage.removeItem("guid");
      }
      setUserInfo(user);
    }, [duration * 1000]);
    return () => clearTimeout(timeout);
  }, [duration, navigate, user]);

  const globalState = {
    user,
    userInfo,
    setUserInfo,
    academies,
    setAcademies,
    allQuestions,
    setAllQuestions,
    duration,
    setDuration,
    startAssessment,
    currentQuestion,
    setCurrentQuestion,
    nextQuestion,
    setNextQuestion,
    setBodyUnscrollable,
    checkIfClickedOutside,
    termsAgreement,
    setTermsAgreement,
    term,
    onTimeOutNavigateUserToResult,
    expirationDate,
    formatExpirationDate,
    resultsDate,
    formatResultsDate,
    showAllRulesPopup,
    setShowAllRulesPopup,
    checkDatesForDisplayingAwards,
  };

  return <Provider value={globalState}>{props.children}</Provider>;
};
