import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import theme from "../../Consts/Theme";
import application_form_background from "./Static/application-form-background.png";
import BrainsterNextLogo from "../../Components/BrainsterNextLogo/BrainsterNextLogo";
import { useTranslation } from "react-i18next";
import { Header32, Text16, Text18, Text50 } from "../../Components/Text/Text";
import ButtonBasic from "../../Components/ButtonBasic/ButtonBasic";
import { GlobalContext } from "../../Context/GlobalContext";
import { Link } from "react-router-dom";
import SelectInput from "../../Components/SelectInput/SelectInput";

// styled-components
const StyledContainer = styled.section`
  height: 100vh;
  width: 100%;
  padding: 100px 0;
  background-color: ${(props) => props.theme.violet};
  background-image: url(${application_form_background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;
const StyledInnerContainer = styled.section`
  text-align: center;
`;
const StyledHeader = styled.header`
  margin-top: 50px;
`;
const StyledBody = styled.section`
  margin-top: 80px;

  div.button-container {
    margin-top: 50px;
  }

  div.button-container button {
    background-color: ${theme.white};
    padding: 15px 20px;
    border-radius: 25px;
    border: none;
    outline: none;
    cursor: pointer;
  }
`;
const StyledPopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  display: ${(props) => (props.showPopup ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .close-modal span {
    width: 40px;
    height: 40px;
    border: 3px solid white;
    border-radius: 50%;
    color: ${theme.white};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 30px;
  }
`;
const StyledPopup = styled.div`
  max-width: 793px;
  padding: 40px;
  background-color: ${(props) => props.theme.white};
  border-radius: 8px;
  position: relative;

  .close-modal,
  .button-box {
    display: flex;
    justify-content: flex-end;
  }

  .label {
    text-align: center;
    margin-bottom: 16px;
  }
  .button-box {
    margin-top: 20px;
  }
`;
const IframeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  iframe {
    width: 780px;
    height: 400px;
    overflow: hidden;
    border: none;
    border-radius: 14px;
    box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    iframe  #picture{
      display: none !important;
    }
  }
`;

const StyledCustomSelect = styled.select`
  display: block;
  width: 100%;
  border-color: ${theme.textGrey};
  min-height: 35px;
  border-radius: 3px;
  padding: ${(props) => (props.widthAuto ? "4px" : "16px 30px 16px 16px")};
  margin: 5px 0;
  border-radius: 4px;
  color: ${(props) => props.theme.textGrey};
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  outline: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  box-sizing: border-box;

  option {
    box-sizing: inherit;
    font-weight: normal;
    display: block;
    white-space: nowrap;
    min-height: 1.2em;
    padding: 0px 2px 1px;
    color: ${(props) => props.theme.textGrey};
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }

  @media (max-width: 768px) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
`;

const salesManagoLink = [
  {
    program: "SEI",
    link: "https://app3.salesmanago.com/cf/4ok5u4iwtjeesaps/MKD_NEXT_Software_Engineering.htm",
  },
  {
    program: "GUX",
    link: "https://app3.salesmanago.com/cf/4ok5u4iwtjeesaps/MKD_NEXT_GUX.htm",
  },
];

const defaultSelectedOption = salesManagoLink[0].link;

const ApplicationClosed = () => {
  const { t } = useTranslation();
  const popupRef = useRef();
  const { academies } = useContext(GlobalContext);
  const [showPopup, setShowPopup] = useState(false);
  const [showProgramApplicationForm, setShowProgramApplicationForm] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultSelectedOption);

  const collegePrograms = academies?.map((academy, index) => ({
    id: academy.id,
    name: academy.name,
    link: salesManagoLink[index].link,
  }));

  const handleChange = (link) => {
    setSelectedOption(link);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef?.current && !popupRef?.current?.contains(event.target)) {
        setShowPopup(false);
        setShowProgramApplicationForm(false);
        setSelectedOption(defaultSelectedOption);
      }
    };
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, [popupRef]);

  return (
    <StyledContainer>
      <StyledInnerContainer>
        <BrainsterNextLogo color={theme.white} />
        <StyledHeader>
          <Header32 color={theme.white} fontWeight={700}>
            {t("application_closed.header_part_one")} <br />{" "}
            {t("application_closed.header_part_two")}
          </Header32>
        </StyledHeader>
        <StyledBody className="waiting-list">
          <Text18 color={theme.white}>
            Влези на листата за чекање за следниот уписен рок кој <br />{" "}
            започнува наскоро!
          </Text18>

          <div className="button-container">
            <button onClick={() => setShowPopup(true)}>
              <Text16 fontWeight={600}> Влези на листата за чекање</Text16>
            </button>
          </div>
        </StyledBody>
      </StyledInnerContainer>

      {/* -------- choose program --------- */}
      <StyledPopupContainer showPopup={showPopup}>
        <StyledPopup ref={popupRef}>
          {!showProgramApplicationForm ? (
            <div className="popup-content">
              <Text18 className="label" fontWeight={700}>
                {t("application_closed.choose_program")}
              </Text18>

              <StyledCustomSelect
                onChange={(e) => handleChange(e.target.value)}
              >
                {collegePrograms?.map((program) => (
                  <option key={program.id} value={program.link}>
                    {program.name}
                  </option>
                ))}
              </StyledCustomSelect>

              <div className="button-box">
                <ButtonBasic
                  onClick={() => setShowProgramApplicationForm(true)}
                  variation="small"
                  text="Понатаму"
                />
              </div>
            </div>
          ) : (
            <IframeContainer>
              <iframe src={selectedOption} frameborder="0"></iframe>
            </IframeContainer>
          )}
        </StyledPopup>
      </StyledPopupContainer>
    </StyledContainer>
  );
};

export default ApplicationClosed;
