import { useState, useEffect } from 'react'

//npm imports
import styled, { keyframes } from 'styled-components/macro';

//component imports
import { Text16 } from '../Text/Text';

const textPulse = keyframes`
    0%{
        transform: scale(1.0);
    }
    50%{
        transform: scale(1.2);
    }
    100%{
        transform: scale(1.0);
    }

`

//styled-components
const StyledCountdownTimer = styled(Text16)`
    display: flex;
    align-items: center;
    color: ${props => props.remainingTime?.minutes === "00" && props.remainingTime?.seconds === "00" && props.theme.textError};
    animation: ${props => props.remainingTime.minutes === "00" && props.remainingTime.seconds === "00" ? "" : props.remainingTime?.minutes === "00" && parseFloat(props.remainingTime.seconds) < 11 && textPulse} 1.33s linear forwards infinite;
    transform: ${props => props.remainingTime.minutes === "00" && props.remainingTime.seconds === "00" && "scale(1.2)"};
    transition: transform 0.5s;
`


const CountdownTimer = ({ duration }) => {
    const defaultRemainingTime = {
        minutes: "XX",
        seconds: "XX"
    }
    const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

    const updateRemainingTime = (countdown) => {
        if (duration) {
            //if countdown divided by 60 floored is greater than 9 return countdown divided by 60 floored
            //if it is not greater than 9 but it is greater than 0, concatenate a 0 before the remaining minutes and return exp: "09"
            //if it is not greater than 0, then return "00"
            let minutes = Math.floor(countdown / 60) > 9 ? Math.floor(countdown / 60) : Math.floor(countdown / 60) > 0 ? "0" + Math.floor(countdown / 60) : "00";
            //same logic lacking Math.floor
            let seconds = countdown % 60 > 9 ? countdown % 60 : countdown % 60 > 0 ? "0" + countdown % 60 : "00";
            return { minutes, seconds }
        } else {
            return { minutes: "", seconds: "" }
        }
    }

    //need counter to count passed seconds
    let secondsCounter = 0;
    //on change on duration in local state
    useEffect(() => {
        //if duration is defined and not equal to 0
        if (duration && duration !== 0) {
            const interval = setInterval(() => {//set interval to every second
                //set countdown to remaining time in local state - passed seconds
                let countdown = parseInt(duration) - secondsCounter;
                //update remaining time based on the countdown
                let updatedTime = updateRemainingTime(countdown);
                //set remaining time in localState to the updated time every second
                setRemainingTime({ ...remainingTime, minutes: updatedTime.minutes, seconds: updatedTime.seconds });
                //increment seconds counter for 1 second
                secondsCounter++
            }, 1000)

            return () => clearInterval(interval);
        }
        //eslint-disable-next-line
    }, [duration])

    return (
        <StyledCountdownTimer remainingTime={remainingTime}>
            <span>{remainingTime.minutes}</span>
            {remainingTime.minutes !== "" && remainingTime.seconds !== "" && ":"}
            <span>{remainingTime.seconds}</span>
        </StyledCountdownTimer>
    )
}

export default CountdownTimer;