import React from "react";

import theme from "../../../../Consts/Theme";
import useMediaCustom from "../../../../Hooks/useMediaCustom";
import TestimonialCarouselDesktop from "./TestimonialCarouselDesktop";
import TestimonialCarouselMobile from "./TestimonialCarouselMobile";
import DavidPetkovski from "./Static/DavidPetkovski.png";
import DavidPetkovskiMobile from "./Static/DavidPetkovskiMobile.png";
import SaraKiselovska from "./Static/SaraKiselovska.png";
import SaraKiselovskaMobile from "./Static/SaraKiselovskaMobile.png";
import StefanPetrov from "./Static/StefanPetrov.png";
import StefanPetrovMobile from "./Static/StefanPetrovMobile.png";

import DavidDesktop from "./Static/DavidDesktop.png";
import SaraDesktop from "./Static/SaraDesktop.png";
import StefanDesktop from "./Static/StefanDesktop.png";





const testimonials = [
  {
    id: 1,
    cardVariant: {
      bgColor: theme.violet,
      color: theme.white,
      fill: theme.pink,
      quoteFill: theme.altBlue
    },
    name: "Давид Петковски",
    gender: "male",
    studies: "Софтверско инженерство и иновации",
    image: DavidPetkovski,
    imageMobile: DavidPetkovskiMobile,
    link: "softwareCollege",
    testimonial:
      "По само една година учење на Brainster Next College, сo колешката Атанасија Ѓоргиевски ја креиравме апликацијатa Bitbite.mk. Со помош на оваа апликација угостителските објекти ќе може сами да креираат QR код мени, односно да го дигитализираат своето мени.",
  },
  {
    id: 2,
    cardVariant: {
      bgColor: theme.gray5,
      color: theme.violet,
      fill: theme.incorrect,
      quoteFill: theme.white

    },
    name: "Сара Киселовска",
    gender: "female",
    studies: "UX/UI и графички дизајн",
    image: SaraKiselovska,
    imageMobile: SaraKiselovskaMobile,
    link: "graphicDesign",
    testimonial:
      "Програмата е одлично конципирана, креативна е, целосно го привлекува  вниманието на студентите и ја задржува концентрацијата. Ние не сме неми слушатели на предавањата туку активни учесници.",
  },
  {
    id: 3,
    cardVariant: {
      bgColor: theme.pink,
      color: theme.white,
      fill: theme.violet,
      quoteFill: theme.incorrect

    },
    name: "Стефан Петров",
    gender: "male",
    studies: "Софтверско инженерство и иновации",
    link: "softwareCollege",
    image: StefanPetrov,
    imageMobile: StefanPetrovMobile,
    testimonial:
      "Со мојот тим победивме на „Air Care“ хакатонот и среќен сум што можев да помогнам за еден од најголемите проблеми на денешницата, загадениот воздух. Со тимот ја програмиравме платформата Breathe.mk на која граѓаните ќе може да пријавуваат загадување, кое потоа ќе го решаваат инспекторите на Град Скопје.",
  },
];

const TestimonialCarousel = () => {
  const isLaptop = useMediaCustom("(min-width: 1024px)");
  return (
    <>
      {isLaptop ? (
        <TestimonialCarouselDesktop testimonials={testimonials} />
      ) : (
        <TestimonialCarouselMobile testimonials={testimonials} />
      )}
    </>
  );
};

export default TestimonialCarousel;
