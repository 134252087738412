import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";

// component imports
import ButtonSquare from "../../../../Components/ButtonSquare/ButtonSquare";

// other imports
import devices_group_desktop from "./Static/devices_group_desktop.webp";
import devices_group_mobile from "./Static/devices_group_mobile.webp";
import questionsIcon from "./Static/questions_icon.svg";
import timeIcon from "./Static/time_icon.svg";
import hero_background from "../HomepageHero/Static/hero-background.png";
import hero_background_mobile from "../HomepageHero/Static/hero-background-mobile.png";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../../../Context/GlobalContext";
import { API } from "../../../../Consts/Api";
import { useTranslation } from "react-i18next";
import theme from "../../../../Consts/Theme";
import {
  Header32,
  Text14,
  Text16,
  TextCustom,
} from "../../../../Components/Text/Text";

// styled-components
const ChooseAcademyTestWrapper = styled.section`
  width: 100%;
  background-image: url(${hero_background});
  background-position: bottom 0%;
  background-size: cover;
  background-color: ${(props) => props.theme.violet};
  position: relative;
  overflow: hidden;

  img.star-horizontal {
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0;
  }

  img.screen-desktop {
    display: block;
    width: 55%;
    position: absolute;
    z-index: 2;
    right: 0;
    right: -20%;
    top: 50%;
    transform: translateY(-40%);
  }

  @media (max-width: 550px) {
    background-color: ${(props) => props.theme.violet};
    background-image: url(${hero_background_mobile});
    background-position: right 35% bottom 45%;
    background-size: cover;
  }
  @media (max-width: 768px) {
    img.star-horizontal {
      display: none;
    }
  }
  @media (max-width: 992px) {
    img.screen-desktop {
      display: none;
    }
  }
  @media (max-width: 1920px) {
    img.screen-desktop {
      width: 50%;
    }
  }
`;
const StyledInnerWrap = styled.div`
  padding-bottom: 100px;
  @media (min-width: 992px) {
    display: flex;
    flex-direction: row-reverse;
    width: 90%;
    max-width: 1235px;
    margin: 0 auto;
  }
`;
const StyledImagesGroup = styled.div`
  margin: 50px 0 0 0;
  position: relative;
  img.mac-book-mobile {
    display: block;
    width: 100%;

    position: absolute;
    top: -90px;
    right: 0;
    z-index: 2;
    object-fit: fill;
  }
  img.iphone-mobile {
    position: absolute;
    bottom: -10px;
    left: 40px;
    display: block;
    width: 45%;
    z-index: 1;
  }
  img.iphone-desktop {
    display: none;
  }
  img.star-vertical {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  img.screen-desktop {
    display: none;
  }

  @media (min-width: 550px) {
    img.mac-book-mobile {
      width: 60%;
    }
  }

  @media (min-width: 769px) {
    text-align: end;
    img.mac-book-mobile {
      display: none;
    }
    img.star-vertical {
      display: block;
      top: 5%;
    }
    img.screen-desktop {
      display: none;
      margin-left: auto;
    }
  }
  @media (min-width: 992px) {
    width: 100%;
    img.iphone-desktop {
      display: block;
      position: absolute;
      top: 50%;
      left: 110px;
      transform: translateY(-30%);
    }
    img.screen-desktop {
      width: 100%;
      display: block;
    }
    img.star-vertical {
      display: none;
    }
  }
`;

const StyledBox = styled.div`
  @media (min-width: 992px) {
    margin-top: 15%;
    /* width: 90%; */
    margin-left: auto;
  }
`;
const StyledButtonsGroup = styled.div`
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-row-gap: 16px;

  @media (min-width: 550px) {
    width: 60%;
    margin-left: 5%;
  }
  @media (min-width: 992px) {
    width: 70%;
    margin-left: 0;

    p.choose-program {
      margin-left: 50px;
    }

    button {
      border-radius: 50px;
      transition: all 0.3s ease-in-out;
    }
  }
`;
const StyledContentWrap = styled.div`
  width: 60%;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (min-width: 992px) {
    margin-top: 15%;
  }
`;
const StyledCaption = styled.div`
  width: 90%;
  margin: 0 auto 26px;
  padding-top: 200px;
  span {
    border-bottom: 1px solid ${theme.pink};
  }
  p {
    text-transform: uppercase;
  }

  @media (min-width: 375px) {
    padding-top: 250px;
  }

  @media (min-width: 550px) {
    padding-top: 270px;
  }
  @media (min-width: 600px) {
    padding-top: 370px;
  }
  @media (min-width: 992px) {
    padding-top: 0;
    width: 100%;
    p {
      display: inline-block;
    }
  }
`;
const StyledContent = styled.div`
  width: 90%;
  margin: 0 auto 58px;

  h4 {
    width: 90%;
    margin-bottom: 8px;
    line-height: 46px;
    font-size: 40px;
  }
  @media (min-width: 992px) {
    width: 100%;
    margin-bottom: 58px;
    h4 {
      width: 90%;
      line-height: 56px;
      font-size: 56px;
    }
    p.description {
      line-height: 32px;
      font-size: 18px;
      width: 90%;
    }
  }
  @media (min-width: 1440px) {
    width: 100%;
    h4 {
      width: 90%;
      font-size: 50px;
      line-height: 55px;
    }
  }
`;
const StyledTestDescription = styled.div`
  margin-top: 32px;
  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 24px;
    h4 {
      width: 90%;
      font-size: 50px;
      line-height: 55px;
    }
  }
`;
const ChooseAcademyTest = (props) => {
  const { t } = useTranslation();
  const scrollToSectionRef = useRef(null);
  const { academies, userInfo } = useContext(GlobalContext);

  const offset = 100;

  useEffect(() => {
    if (props.scrollToTests) {
      const topPosition =
        scrollToSectionRef?.current?.getBoundingClientRect()?.top +
        window.scrollY -
        offset;
      window.scrollTo({ top: topPosition, behavior: "smooth" });
      props.resetButton();
    }
  }, [props.scrollToTests]);

  return (
    <ChooseAcademyTestWrapper>
      <StyledInnerWrap>
        <StyledBox ref={scrollToSectionRef}>
          <StyledImagesGroup>
            <img
              className="mac-book-mobile"
              src={devices_group_mobile}
              alt="Laptop"
            />
          </StyledImagesGroup>
        </StyledBox>
        <StyledContentWrap className="caption">
          <StyledCaption>
            <Text16
              color={theme.white}
              fontWeight={600}
              lineHeight="24"
              dangerouslySetInnerHTML={{
                __html: t("homepage.choose_academy_test.header_part_one"),
              }}
            ></Text16>
          </StyledCaption>
          <StyledContent>
            <Header32
              color={theme.white}
              fontWeight={600}
              dangerouslySetInnerHTML={{
                __html: t("homepage.choose_academy_test.title"),
              }}
            ></Header32>

            {/* <StyledTestDescription>
              <div>
                <img src={questionsIcon} alt="" />
                <TextCustom
                  fontSize="18"
                  lineHeight="24"
                  fontWeight={600}
                  color="white"
                >
                  {t("homepage.choose_academy_test.total_questions")}
                </TextCustom>
              </div>
              <div>
                <img src={timeIcon} alt="" />
                <TextCustom
                  fontSize="18"
                  lineHeight="24"
                  fontWeight={600}
                  color="white"
                >
                  {t("homepage.choose_academy_test.total_time")}
                </TextCustom>
              </div>
            </StyledTestDescription> */}
          </StyledContent>
          <StyledButtonsGroup>
            <TextCustom
              fontSize="14"
              lineHeight="16"
              fontWeight={500}
              color="white"
              className="choose-program"
            >
              {t("homepage.choose_academy_test.choose_program")}
            </TextCustom>
            {academies?.length > 0 &&
              academies.map((academy) => (
                <React.Fragment key={academy.id}>
                  <Link
                    target="_blank"
                    to={
                      userInfo.academy_id
                        ? API.createRoute("ongoing")
                        : userInfo.referred_by !== null
                        ? API.createRoute("applyWithReferral", {
                            REFERRAL_GUID: userInfo.referred_by,
                            ACADEMY_ID: academy.id,
                          })
                        : API.createRoute("apply", { ACADEMY_ID: academy.id })
                    }
                  >
                    <ButtonSquare
                      btnText={academy.name}
                      borderColor={
                        academy.id === 1 ? `${theme.altBlue}` : `${theme.pink}`
                      }
                      hoverColor={
                        academy.id === 1
                          ? "linear-gradient(92.5deg, #7497FF -3.1%, #FFFFFF 106.84%);"
                          : "linear-gradient(92.5deg, #FFA9C4 -3.1%, #FFFFFF 106.84%);"
                      }
                    />
                  </Link>
                </React.Fragment>
              ))}
          </StyledButtonsGroup>
        </StyledContentWrap>
      </StyledInnerWrap>
      <img
        className="screen-desktop"
        src={devices_group_desktop}
        alt="Screen"
      />
    </ChooseAcademyTestWrapper>
  );
};

export default ChooseAcademyTest;
