import { useContext, useEffect } from 'react';

//npm imports
import { Link, useNavigate } from 'react-router-dom';
import styled, { withTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

//component imports
import { AssessmentWrapper } from '../Assessment/Assessment';
import CountdownTimer from '../../Components/CountdownTimer/CountdownTimer';
import { Header32, Text18 } from '../../Components/Text/Text';
import ButtonWide from '../../Components/ButtonWide/ButtonWide';
import BrainsterNextLogo from '../../Components/BrainsterNextLogo/BrainsterNextLogo';

//other imports
import { API } from '../../Consts/Api';
import { GlobalContext } from '../../Context/GlobalContext';
import { hourglass } from './Static/hourglassIcon.js';
import background from './Static/ongoing-background.png'

//styled-components
const OngoingWrapper = styled(AssessmentWrapper)`
    &>form{
        div.mobile-background-container{

            .brainster-logo-container{
                margin-top: 0px;
            }
            &>.header{
                width: 250px;
                height: 96px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                align-items: center;
                margin: 160px auto 56px auto;
                background-color: ${props => props.theme.violet};
                border: 1px solid ${props => props.theme.black};
                border-radius: 47px;
                .timer{
                    transform: scale(1.3);
                    p{
                        color: ${props => props.theme.white};
                    }
                }
            }
        }
        ${Header32}, ${Text18}{
            text-align: center;
        }
        
        ${Text18}{
            width: 80%;
            margin: 24px auto 24px auto;
        }
    }
    @media (max-width: 768px){
        &>form{
            width: 100%;
            padding: 0px 0 23px 0;
            div.mobile-background-container{
                background-color: ${props => props.theme.violet};
                background-image: url(${background});
                background-size: cover;
                background-position: center top;
                background-repeat: no-repeat;
                .brainster-logo-container{
                    margin: 60px auto 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    a{
                        width: max-content;
                    }
                    svg{
                        path{
                            fill: ${props => props.theme.white};
                        }
                    }
                }
                &>.header{
                    padding-bottom: 0px;
                    margin-bottom: -47px!important;
                    margin-top: 71px;
                    background-color: ${props => props.theme.violet};
                    .timer {
                        padding: 16px 32px;
                        border-radius: 100px;
                        margin-top: 0px;
                    }
                }
            }
        }
            ${Header32}, ${Text18}, a{
                width: 90%;
                margin-left: auto;
                margin-right: auto;
            }
            ${Header32}{
                margin-top: 75px;
            }
            ${Text18}{
                margin: 24px auto 29px auto
            }
        }
    }
`

//main components
const Ongoing = (props) => {
    const { t } = useTranslation();
    const { userInfo, startAssessment, duration, onTimeOutNavigateUserToResult } = useContext(GlobalContext);

    let navigate = useNavigate();

    useEffect(() => {
        userInfo?.academy_id && startAssessment(userInfo.academy_id);
        //eslint-disable-next-line
    }, [userInfo.academy_id])

    useEffect(() => {

        if (typeof duration === "number" && duration >= 0) {
            onTimeOutNavigateUserToResult();
        }
        //eslint-disable-next-line
    }, [duration])


    useEffect(() => {
        //if user has no active assessment session, redirect user to homepage
        if (localStorage.getItem("guid") === null) {
            navigate(API.createRoute("home"));
        }
        //eslint-disable-next-line
    }, [])
    return (
        <OngoingWrapper duration={duration} selectedAnswerId={true}>
            <form>
                <div className="mobile-background-container">

                    <div className='brainster-logo-container'>
                        <Link to={API.createRoute("home")}>
                            <BrainsterNextLogo color={props.theme.black} />
                        </Link>
                    </div>

                    <div className="countdown-progress">
                        <div className="countdown-progressbar"></div>
                    </div>
                    <div className="header">
                        <div className="timer">
                            <CountdownTimer duration={duration ? duration - 2 : duration} />
                            {hourglass}
                        </div>
                    </div>
                </div>
                <Header32>{t("ongoing.header")}</Header32>
                <Text18 color={props.theme.gray2}>{t("ongoing.description")}</Text18>
                <Link to={API.createRoute("assessment")}><ButtonWide text={t("ongoing.resume_cta")} /></Link>
            </form>
        </OngoingWrapper >
    )
}

export default withTheme(Ongoing);