export const getReady = <svg width="285" height="85" viewBox="0 0 285 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="281" height="72" transform="translate(0 12.9768) rotate(-2.6469)" fill="#4B4BFF" />
    <path d="M57.1673 56.8636C54.8844 59.4518 52.1846 60.8179 49.068 60.962C47.1766 61.0494 45.4007 60.7445 43.74 60.0471C42.0781 59.3231 40.6171 58.3228 39.3568 57.0463C38.0965 55.7698 37.0794 54.2685 36.3055 52.5424C35.5583 50.8151 35.1403 48.9924 35.0517 47.0745C34.963 45.1565 35.2116 43.3164 35.7975 41.5541C36.4101 39.7906 37.2855 38.2285 38.4239 36.8679C39.5623 35.5072 40.94 34.4157 42.5571 33.5935C44.173 32.7446 45.9532 32.2752 47.8978 32.1853C50.6149 32.0597 52.9189 32.5272 54.8099 33.5876C56.6997 34.6215 58.1538 36.0492 59.1724 37.8707L55.8506 40.507C55.0066 38.9977 53.8875 37.8882 52.4933 37.1784C51.099 36.4687 49.5894 36.1515 47.9645 36.2266C46.6326 36.2882 45.4341 36.6372 44.369 37.2737C43.3027 37.8836 42.4055 38.6859 41.6773 39.6806C40.9479 40.6487 40.399 41.7685 40.0307 43.0402C39.689 44.3107 39.5495 45.6252 39.6123 46.9838C39.6788 48.4222 39.9675 49.757 40.4782 50.988C41.0156 52.2179 41.7054 53.2805 42.5476 54.1759C43.4165 55.07 44.4233 55.771 45.5679 56.2786C46.7114 56.7597 47.9357 56.97 49.241 56.9097C50.6795 56.8432 52.0359 56.4468 53.3104 55.7205C54.6115 54.993 55.8282 53.8823 56.9604 52.3884L56.82 49.3516L50.8664 49.6269L50.7131 46.3104L60.4227 45.8615L61.0859 60.2062L57.3299 60.3798L57.1673 56.8636Z" fill="white" />
    <path d="M85.4668 55.1148L85.6496 59.0706L66.1904 59.9702L64.8789 31.6005L83.9785 30.7175L84.1614 34.6733L69.537 35.3494L69.9101 43.4208L82.5766 42.8352L82.7484 46.5512L70.0819 47.1368L70.4828 55.8076L85.4668 55.1148Z" fill="white" />
    <path d="M109.955 33.4809L100.525 33.9168L101.654 58.3307L97.1389 58.5395L96.0103 34.1255L86.5404 34.5633L86.3575 30.6076L109.773 29.5251L109.955 33.4809Z" fill="white" />
    <path d="M123.629 57.3148L122.318 28.9451L134.664 28.3743C135.943 28.3152 137.127 28.5274 138.218 29.0109C139.334 29.4932 140.299 30.156 141.112 30.9994C141.924 31.8161 142.569 32.774 143.047 33.8731C143.551 34.9443 143.829 36.0526 143.882 37.1981C143.926 38.1571 143.835 39.0822 143.61 39.9736C143.384 40.865 143.048 41.6947 142.603 42.4627C142.158 43.2308 141.603 43.9105 140.936 44.5019C140.268 45.0667 139.527 45.5014 138.714 45.8059L145.885 56.2859L140.811 56.5205L134.201 46.9355L127.648 47.2385L128.104 57.1079L123.629 57.3148ZM127.465 43.2827L135.297 42.9207C135.936 42.8911 136.503 42.7314 136.996 42.4416C137.516 42.124 137.951 41.7168 138.301 41.2201C138.651 40.6967 138.918 40.1104 139.101 39.4613C139.285 38.8121 139.36 38.1279 139.327 37.4087C139.293 36.6894 139.129 36.0163 138.833 35.3893C138.563 34.7611 138.205 34.2171 137.756 33.7573C137.335 33.2963 136.838 32.9455 136.266 32.705C135.72 32.4366 135.154 32.316 134.568 32.343L126.976 32.694L127.465 43.2827Z" fill="white" />
    <path d="M169.323 51.2382L169.505 55.194L150.046 56.0936L148.735 27.7239L167.834 26.8409L168.017 30.7967L153.393 31.4728L153.766 39.5441L166.432 38.9586L166.604 42.6746L153.938 43.2602L154.339 51.9309L169.323 51.2382Z" fill="white" />
    <path d="M181.162 26.2248L185.077 26.0437L197.577 53.8962L192.862 54.1142L189.509 46.541L178.56 47.0471L175.96 54.8956L171.205 55.1154L181.162 26.2248ZM188.476 43.2652L183.358 31.2888L179.206 43.6937L188.476 43.2652Z" fill="white" />
    <path d="M201.085 53.734L199.774 25.3643L209.843 24.8988C212.134 24.7929 214.149 25.0735 215.888 25.7405C217.654 26.4064 219.14 27.3521 220.344 28.5778C221.575 29.8022 222.524 31.2667 223.19 32.9711C223.855 34.6488 224.232 36.46 224.322 38.4046C224.422 40.5623 224.18 42.5356 223.595 44.3245C223.036 46.0855 222.186 47.6197 221.045 48.9272C219.903 50.2079 218.495 51.234 216.822 52.0054C215.148 52.7502 213.259 53.1712 211.155 53.2685L201.085 53.734ZM219.767 38.6152C219.698 37.1234 219.422 35.7614 218.938 34.5291C218.48 33.2956 217.831 32.2578 216.992 31.4156C216.151 30.5469 215.146 29.8859 213.977 29.4328C212.808 28.9796 211.491 28.7869 210.026 28.8546L204.432 29.1132L205.378 49.5714L210.972 49.3127C212.464 49.2438 213.77 48.9298 214.892 48.3707C216.04 47.7837 216.979 47.0196 217.71 46.0781C218.466 45.1089 219.015 43.989 219.356 42.7185C219.698 41.448 219.835 40.0803 219.767 38.6152Z" fill="white" />
    <path d="M228.698 24.0272L237.187 37.8899L244.441 23.2994L249.356 23.0721L239.61 41.7822L240.079 51.9314L235.604 52.1383L235.131 41.9092L223.783 24.2544L228.698 24.0272Z" fill="white" />
</svg>
