import React, { useRef } from 'react';

//npm imports
import styled, { css, withTheme } from 'styled-components/macro';

// Components
import { Text14, Text18 } from '../Text/Text';

// Styled components
const CheckboxContainer = styled.div`
    width: ${props => props.variation === "large" ? "40px" : "24px"};
    height: ${props => props.variation === "large" ? "40px" : "24px"};
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.variation === "large" ? props.theme.black : props.active ? props.theme.gray2 : props.theme.gray2};
    transition: background 0.3s ${props => props.theme.expo};
    input {
        display: none;
        visibility: hidden;
        opacity: 0;
    }
    .checkmark {
        transition: 0.5s ${props => props.theme.expo};
        transform-origin: center center;
        ${props => props.checked ? css`
            transform: scale(${props => props.variation === "large" ? "2" : "1"});
        `: css`
            transform: scale(0);
        `};
    }
    @media (max-width: 500px){
        svg{
            width: 9px;
            height: 9px;
        }
    }
    `;

const CheckboxLabel = styled.label`
    display: inline-flex;
    align-items: center;
    ${CheckboxContainer}:hover, ${Text18}:hover, ${Text14}:hover{

        cursor: pointer;
    }
    p {
        margin-left: 16px;
        width: calc(100% - ${props => props.variation === "large" ? "56px" : "40px"});
    }
    .link{
        // font-size: 14px;
        color: ${props => props.theme.active};
    }
`;


// Component
const Checkbox = ({ theme, checked, onClick, text, boldText, error, isFilterButton, className, link, variation }) => {
    const checkmark = useRef(null);

    return (
        <CheckboxLabel isFilterButton={isFilterButton} className={className} variation={variation}>
            <CheckboxContainer checked={checked} onChange={onClick} variation={variation} >
                <input type="checkbox" value={checked} />
                <svg ref={checkmark} className="checkmark" checked={checked} width="15" height="15" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.99994 10.8001L3.19994 8.00007L2.2666 8.9334L5.99994 12.6667L13.9999 4.66673L13.0666 3.7334L5.99994 10.8001Z" fill={theme.yellow} />
                </svg>
            </CheckboxContainer>
            {variation === "large"
                ? <Text18 color={error ? theme.textError : variation === "large" ? theme.white : theme.gray1}>{typeof error === "string" ? error : text && text}{boldText && <strong>{boldText}</strong>}{link?.text && <span className='link' onClick={link.onClick}>{link.text}</span>}</Text18>
                : <Text14 color={error ? theme.textError : variation === "large" ? theme.white : theme.gray1}>{typeof error === "string" ? error : text && text}{boldText && <strong>{boldText}</strong>}{link?.text && <span className='link' onClick={link.onClick}>{link.text}</span>}</Text14>
            }
        </CheckboxLabel >
    )
}

export default withTheme(Checkbox);
