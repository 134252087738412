import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { Text12, Text16, Text18 } from "../../../../Components/Text/Text";
import theme from "../../../../Consts/Theme";

// styled-components
const StyledCardContainer = styled.article`
  flex: 0 0 90%;
  :not(:last-child) {
    margin-right: 16px;
  }
`;

const StyledImageWrap = styled.div`
  position: relative;
  background-color: ${(props) => props.bgColor};
  border-radius: 8px;
  padding-top: 60px;

  overflow: hidden;

  img {
    display: block;
    position: relative;
    z-index: 2;
    width: 100%;
  }
  svg {
    position: absolute;
  }
  svg.front-star {
    bottom: 0;
    left: 0;
    z-index: 3;
  }
  svg.back-star {
    right: 0;
    top: 0;
  }
`;

const StyledContentWrap = styled.div`
  margin-top: 35px;

  svg.double-quote {
    top: 0;
    left: 0;
  }
  .testimonial {
    margin-top: 8px;
  }
  .student-name {
    text-transform: uppercase;
    margin-top: 20px;
  }
  .field-of-study {
    font-size: 10px;
  }
`;

const CarouselCardMobile = ({ testimonial }) => {
  const { t } = useTranslation();
  return (
    <StyledCardContainer>
      <StyledImageWrap bgColor={testimonial.cardVariant.bgColor}>
        <img src={testimonial.imageMobile} alt="" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="118"
          height="97"
          viewBox="0 0 118 97"
          fill="none"
          className="front-star"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34.9733 0H21.1106V73.5537L-30.0201 21.5414L-39.8225 31.5128L11.3079 83.5248H-61V97.6265H11.306L-39.8226 149.637L-30.0202 159.608L21.1106 107.596V181.153H34.9733V107.598L86.1006 159.607L95.903 149.636L44.7753 97.6265H117.082V83.5248H44.7735L95.9029 31.5139L86.1005 21.5425L34.9733 73.5512V0Z"
            fill={testimonial.cardVariant.fill}
          />
        </svg>
        <svg
          width="225"
          height="398"
          viewBox="0 0 225 398"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="back-star"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M199 -77H171V76.575L64.4348 -31.8276L44.0729 -11.1147L150.285 96.9289H0.0822754V126.221H150.276L44.0725 234.256L64.4343 254.969L171 146.566V409H199V146.484L305.644 254.966L326.006 234.254L219.805 126.221H370V96.9289H219.796L326.006 -11.1121L305.644 -31.825L199 76.6574V-77Z"
            fill={testimonial.cardVariant.fill}
          />
        </svg>
      </StyledImageWrap>
      <StyledContentWrap>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="20"
          viewBox="0 0 25 20"
          fill="none"
          className="double-quote"
        >
          <path
            d="M1.41509 20L3.09973 10.7591H0V0H11.0512V11.0231L8.42318 20H1.41509ZM15.4313 20L17.1159 10.7591H14.0162V0H25V11.0231L22.4394 20H15.4313Z"
            fill="#7497FF"
          />
        </svg>
        <Text16
          className="testimonial"
          lineHeight="24"
          fontWeight={500}
          color={theme.white}
        >
          {testimonial.testimonial}
        </Text16>
        <Text18 className="student-name" color={theme.white}>
          <em>{testimonial.name}</em>
        </Text18>
        <Text12 className="field-of-study" color={theme.white}>
          {t("homepage.testimonials.student")} {t("homepage.testimonials.on")}{" "}
          {testimonial.studies}
        </Text12>
      </StyledContentWrap>
    </StyledCardContainer>
  );
};

export default CarouselCardMobile;
