import { useState, useEffect, useContext, useRef, useCallback } from 'react';

//npm imports
import { Link } from 'react-router-dom';
import styled, { withTheme, keyframes } from 'styled-components/macro';
import { useParams, useNavigate } from 'react-router';

//component imports
import { Text14, Text18, Text21 } from '../../Components/Text/Text';
import Checkbox from '../../Components/Checkbox/Checkbox';
import ButtonWide from '../../Components/ButtonWide/ButtonWide';
import ButtonBasic from '../../Components/ButtonBasic/ButtonBasic';
import ArrowBack from '../../Components/ArrowBack/ArrowBack';

//other imports
import { GlobalContext } from '../../Context/GlobalContext';
import { API } from '../../Consts/Api';
import { useTranslation } from 'react-i18next';
import BrainsterNextLogo from '../../Components/BrainsterNextLogo/BrainsterNextLogo'
import { rulesSpinner, rulesSpinnerMobile } from './Static/rulesSpinner';
import coin_vertical from './Static/coin-vertical.png';
import rules_agreement_background from './Static/rules-agreement-background.png';
import rules_agreement_background_mobile from './Static/rules-agreement-background-mobile.png';
import { getReady } from './Static/getReady';

const spin = keyframes`
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(-360deg);
    }
`

const RulesAgreementWrapper = styled.section`
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 56px 0 80px 0;
    background-color: ${props => props.theme.violet};
    background-image: url(${rules_agreement_background});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &>.rules-agreement-container{
        width: 100%;
        display: flex;
        aside{
            width: 36%;
            padding-left: 5%;
            position: relative;
            display: flex;
            flex-direction: column;
            position: relative;
            &>svg:first-child{
                margin-left: 40px;
            }
            &>svg:last-child{
                animation: ${spin} 4s linear forwards infinite;
                margin-top: 100px;
            }
            &>img{
                position: absolute;
                top: 20%;
                left: 0;
            }
        }
        main{
            width: 64%;
            .main-header{
                .main-header-mobile-controls{
                    display: none;
                }
                .important-banner{
                    display: flex;
                    justify-content: space-between;
                    padding-right: 7%;
                    padding-top: 100px;
                    border-bottom: 1px solid ${props => props.theme.black};
                    &>div{
                        width: 75%;
                        &>div{
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 40px;
                            &>svg{
                                width: 5%;
                                margin-right: 12px;
                                margin-top: 4px;
                            }
                            p{
                                width: 95%;
                                line-height: 30px;
                            }
                        }
                    }
                }
            }
            .rules-checkboxes-container{
                padding-right: 7%;
                margin-bottom: 72px;
                &>div{
                    margin-top: 50px;
                    &>label{
                        align-items: flex-start;
                        margin: 20px 0;
                        &>div{
                            margin-top: 4px;
                        }
                    }
                    &>p{
                        margin-bottom: 44px;
                    }
                }
                &>svg{
                    display: none;
                }
            }
            .academy-confirmation-container{
                margin-top: 44px;
                &>p{
                    margin-bottom: 8px;
                }
                &>div{
                    display: flex;
                    align-items: center;
                    &>p{
                        margin-right: 30px;
                    }
                    &>div{
                        width: 38%;
                        .input-label{
                            margin: 0;
                        }
                    }
                }
            }
            &>button{
                width: 90%;
                margin-bottom: 100px;
                background-color: ${props => props.theme.black};
            }
        }
    }
    .start-popup-container{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,0.5);
        display: ${props => props.showPopup ? "flex" : "none"};
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .start-popup{
            max-width: 793px;
            padding: 0px 40px 40px 40px;
            background-color: ${props => props.theme.white};
            border-radius: 8px;
            position: relative;
            .exit-popup-button{
                border: 1px solid transparent;
                border-bottom-color:  ${props => props.theme.gray2};
                color: ${props => props.theme.gray2};
                font-size: 14px;
                line-height: 22px;
                background: transparent;
                padding: 0;
                margin-right: 40px;
                &:hover{
                    cursor: pointer;
                }
            }
            .start-popup-content{
                display: flex;
                flex-direction: column;
                align-items: center;
                header{
                    display: flex;
                    align-items: center;
                    align-self: flex-start;
                    margin-left: 30px;
                    &>svg{
                        margin-top: -40px;
                        margin-left: -100px:
                        z-index: 9;
                    }
                }
                &>div.buttons-container{
                    align-self: flex-end;
                }
                &>p{
                    margin: 16px 0px 40px 0px;
                    color: ${props => props.theme.gray2};
                }
            }
            img{
                position: absolute;
                bottom: -2px;
                left: -20px;
            }
        }
    }
    @media (max-width: 1100px){
        &>.rules-agreement-container{
            main{
                .academy-confirmation-container{
                    &>div{
                        &>p{
                            width: 45%;
                        }
                        &>div{
                            width: 50%;
                        }
                    }
                }
            }
        }
        .start-popup-container{
            .start-popup{
                padding: 0px 32px 40px 32px;
            }
        }
    }
    @media (max-width: 825px){

        &>.rules-agreement-container{
            width: 100%;
            flex-direction: column;
            padding: 0;
            aside{
                display: none;
            }
            main{
                width: 100%;
                .main-header{
                    width: 100%;
                    padding-bottom: 40px;
                    padding-top: 32px;
                    &>div{
                        width: 82%;
                        margin: 0 auto;
                    }
                    .main-header-mobile-controls{
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 43px;
                    }
                    .important-banner{
                        padding: 0;
                        margin-bottom: 72px;
                        flex-direction: column;
                        &>p{
                            margin-bottom: 40px;
                        }
                        &>div{
                            width: 100%;
                        }
                        svg{
                            margin-right: 16px;
                        }
                    }
                }
                .rules-checkboxes-container>div ,.academy-confirmation-container {
                    width: 82%;
                    margin: 0 auto;
                }
                .rules-checkboxes-container{
                    width: 100%;
                    margin-top: -40px;
                    border-top-right-radius: 40px;
                    padding: 24px 0;
                }
                .academy-confirmation-container{
                    margin-top: 24px;
                    background-color: ${props => props.theme.veryLightGray};
                    &>p{
                        color: ${props => props.theme.darkGray};
                    }
                    &>div{
                        flex-direction: column;
                        margin-bottom: 64px;
                        &>p, &>div{
                            width: 100%;
                        }
                        &>p{
                            margin: 0;
                            margin-bottom: 16px;
                            margin-top: 24px;
                        }
                    }
                }
                &>button{
                    width: 82%;
                    margin: 0 auto 68px auto;
                }
            }
        }
        .start-popup-container{
            .start-popup{
                max-width: 500px;
            }
        }
    }

    @media (max-width: 550px){
        padding: 0;
        background-image: url(${rules_agreement_background_mobile});
        background-size: 100%;
        background-position: top;
        &>.rules-agreement-container{
            main{
                .main-header{
                    &>div{
                        width: 92%;
                    }
                    .important-banner{
                        width: 95%;
                        margin-right: 0;
                        padding-right: 5%;
                    }
                }
                .rules-checkboxes-container{
                    position: relative;
                    &>svg{
                        display: block;
                        position: absolute;
                        top: -10px;
                        right: 30px;
                        animation: ${spin} 4s linear forwards infinite;
                    }
                }
                .rules-checkboxes-container>div ,.academy-confirmation-container {
                    width: 92%;
                    margin: 0 auto;
                }
                .academy-confirmation-container{
                    margin-top: 24px;
                }
                &>button{
                    width: 92%;
                    background-color: ${props => props.theme.black};
                }
            }
        }
        .start-popup-container{
            .start-popup{
                width: 90%;
                max-width: unset;
                padding: 0px 24px 40px 24px;
                .start-popup-content{
                    header{
                        margin-left: 0;
                    }
                    &>div.buttons-container{
                        width: 100%;
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;
                        &>button:first-child{
                            width: max-content;
                            margin-right: 0;
                        }
                        &>button:last-child{
                            width: 100%;
                            margin-bottom: 32px;
                        }
                    }
                    &>p{
                        margin: 24px 0px 48px 0px;
                    }
                }
            }
        }
    }

    @media (max-width: 350px){
        .start-popup-container{
            .start-popup{
                max-width: 300px;
            }
        }
    }
`


const RulesAgreement = (props) => {
    const { t } = useTranslation();
    const { setUserInfo, userInfo, academies, setBodyUnscrollable, checkIfClickedOutside, expirationDate } = useContext(GlobalContext);

    const rules = [
        {
            id: 1,
            text: t("rules_agreement.rules.1"),
            agreed: true,
            error: false
        },
        {
            id: 2,
            text: t("rules_agreement.rules.2"),
            agreed: false,
            error: false
        },
        {
            id: 3,
            text: t("rules_agreement.rules.3"),
            agreed: false,
            error: false
        },
        {
            id: 4,
            text: t("rules_agreement.rules.4"),
            agreed: false,
            error: false
        },
        {
            id: 5,
            text: t("rules_agreement.rules.5"),
            agreed: false,
            error: false
        },
        {
            id: 6,
            text: t("rules_agreement.rules.6"),
            agreed: false,
            error: false
        }
    ]
    const [rulesAgreement, setRulesAgreement] = useState(rules);
    const [areRulesAgreed, setAreRulesAgreed] = useState(false);

    const popupRef = useRef();
    const [showPopup, setShowPopup] = useState(false);

    let params = useParams();
    let navigate = useNavigate();


    useEffect(() => {
        return () => setBodyUnscrollable(false);
        //eslint-disable-next-line
    }, [])


    const agreeToARule = (ruleId) => {
        let rules = [...rulesAgreement];
        let agreedRules = [];
        rules.forEach(rule => {
            if (rule.id === ruleId) {
                rule.agreed = !rule.agreed;
                rule.error = false;
            }
            if (rule.agreed) {
                agreedRules.push(rule.id);
            } else {
                agreedRules.forEach((agreedRule, index) => {
                    if (rule.id === agreedRule) {
                        agreedRules.splice(index, 1)
                    }
                })
            }
        })
        if (agreedRules.length === rulesAgreement.length) {
            setAreRulesAgreed(true)
        } else if (areRulesAgreed) {
            setAreRulesAgreed(false)
        }
        setRulesAgreement(rules)
    }

    const validateRulesAgreement = () => {
        if (!areRulesAgreed) {
            let rules = [...rulesAgreement];
            rules.length > 0 && rules.forEach(rule => {
                if (!rule.agreed) {
                    rule.error = true;
                }
            })
            setRulesAgreement(rules)
        } else {
            setShowPopup(true);
        }
    }

    useEffect(() => {
        setBodyUnscrollable(showPopup)
        //eslint-disable-next-line
    }, [showPopup])


    const handleKeys = useCallback((e) => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            setUserInfo({ ...userInfo, academy_id: params.academyId })
            navigate(API.createRoute("assessment"));
        }
        if (e.keyCode === 27) {
            setShowPopup(false);
        }
        //eslint-disable-next-line
    }, [setShowPopup, navigate])

    useEffect(() => {
        if (showPopup) {
            window.addEventListener('keydown', handleKeys);

        } else if (!showPopup) {
            window.removeEventListener("keydown", handleKeys)
        }

        return () => {
            window.removeEventListener('keydown', handleKeys);
        };
        //eslint-disable-next-line
    }, [showPopup])


    return (
        <RulesAgreementWrapper showPopup={showPopup}>
            <div className="rules-agreement-container">
                <aside>
                    <BrainsterNextLogo color={props.theme.white} />
                    <img src={coin_vertical} alt="coin" />
                    {rulesSpinner}
                </aside>
                <main>
                    <div className="main-header">
                        <div className='main-header-mobile-controls'>
                            <Link to={API.createRoute("apply", { ACADEMY_ID: params.academyId })} >
                                <ArrowBack color={props.theme.white} />
                            </Link>
                            <BrainsterNextLogo color={props.theme.white} />
                        </div>
                        <div className="important-banner">
                            <Text14 color={props.theme.white} fontWeight={700}>{t("rules_agreement.important_banner.rules")}</Text14>
                            <div>
                                <div>

                                    <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.79238 0L0 2.72096L5.41995 8.00229L1.84683e-06 13.2836L2.79238 16L11 8.00229L2.79238 0Z" fill="#FED445" />
                                    </svg>

                                    <Text21 lineHeight={26} color={props.theme.white}>
                                        {t("rules_agreement.important_banner.read_the_rules")}
                                    </Text21>
                                </div>
                                <div>

                                    <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.79238 0L0 2.72096L5.41995 8.00229L1.84683e-06 13.2836L2.79238 16L11 8.00229L2.79238 0Z" fill="#FED445" />
                                    </svg>
                                    <Text21 lineHeight={26} color={props.theme.white}>

                                        {t("rules_agreement.important_banner.check_boxes")}
                                    </Text21>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rules-checkboxes-container">
                        {rulesSpinnerMobile}
                        <div>
                            <Text14 fontWeight={700} color={props.theme.white}>{t("rules_agreement.rules.header")}</Text14>
                            {rulesAgreement.length > 0 && rulesAgreement.map((rule, index) =>
                            (
                                <Checkbox
                                    variation="large"
                                    error={rule.error}
                                    key={rule.id}
                                    onClick={() => agreeToARule(rule.id)}
                                    text={
                                        rule.text
                                            .replace("{ASSESSMENT_DURATION}", academies.length > 0 && academies.filter(academy => academy.id === parseFloat(params.academyId))[0]?.duration / 60)
                                            .replace("{EXPIRATION_DATE}", `${expirationDate.getDate() < 10 ? "0" : ""}${expirationDate.getDate()}.${expirationDate.getMonth() < 9 ? "0" : ""}${expirationDate.getMonth()}.${expirationDate.getFullYear()}`)
                                    }
                                    className="rule-checkbox"

                                    checked={rule.agreed}
                                />
                            ))}
                        </div>
                    </div>

                    <ButtonWide onClick={() => validateRulesAgreement()} text={t("general.continue")} />
                </main>
            </div>
            <div onClick={(e) => checkIfClickedOutside(e, popupRef, () => setShowPopup())} className="start-popup-container">
                <div ref={popupRef} className="start-popup">
                    <div className="start-popup-content">
                        <header>{getReady}</header>
                        <Text18>{t("rules_agreement.get_ready_popup.description").replace("{ASSESSMENT_DURATION}", academies.length > 0 && academies.filter(academy => academy.id === parseFloat(params.academyId))[0]?.duration / 60).replace("{QUESTION_NUMBER}", academies.length > 0 && academies.filter(academy => academy.id === parseFloat(params.academyId))[0]?.question_number)}</Text18>
                        <div className="buttons-container">

                            <button className="exit-popup-button" onClick={() => setShowPopup(false)}>
                                {t("rules_agreement.get_ready_popup.exit_cta")}
                            </button>
                            <ButtonBasic variation="large" filling={"black"} onClick={() => [setUserInfo({ ...userInfo, academy_id: params.academyId }), navigate(API.createRoute("assessment"))]} text={t("rules_agreement.get_ready_popup.start_cta")} />
                        </div>
                    </div>
                </div>
            </div>
        </RulesAgreementWrapper>
    )
}

export default withTheme(RulesAgreement);