import React, { useState } from "react";
import styled from "styled-components/macro";

import CarouselCardDesktop from "./CarouselCardDesktop";

// styled-components
const StyledWrap = styled.div`
  display: flex;
  overflow: hidden;
`;

const TestimonialCarouselDesktop = ({ testimonials }) => {
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  return (
    <StyledWrap>
      {testimonials?.map((testimonial, index) => (
        <CarouselCardDesktop
          key={testimonial.id}
          testimonial={testimonial}
          index={index}
          activeCardIndex={activeCardIndex}
          onCardClick={() => setActiveCardIndex(index + 1)}
          isFirstCard={index === 0}
        />
      ))}
    </StyledWrap>
  );
};

export default TestimonialCarouselDesktop;
