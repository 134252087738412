import { useState } from 'react';
import Select from 'react-select';

//npm imports
import styled, { withTheme } from 'styled-components/macro';

//component imports
// import { Caption12 } from '../Text/Text';

//styled-components
const SelectInputWrapper = styled.div`
    height: 60px;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    .css-b62m3t-container{
        height: 60px;
        .select-input__control{
            height: 60px;
           
        }
    }
    .css-yk16xz-control, .css-1pahdxg-control{
        border-color: ${props => props.theme.gray3};
        border-radius: 7px;
        height: 60px;
    }
    .css-1pahdxg-control{
        outline: none!important;
        box-shadow: 0 0 0 2px transparent!important;
        height: 60px;
    }

    .select-input__control:hover{
        border-color: ${props => props.isSelectOpen ? props.theme.gray1 : props.error && props.theme.textError}!important;
        cursor: pointer;
        .select-input__indicator{
            svg{
                path{
                    fill: ${props => props.theme.gray1};
                }
            }
        }
        .select-input__single-value{
            color: ${props => props.theme.gray1};
        }
    }

    *::-webkit-scrollbar{
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    .css-1hb7zxy-IndicatorsContainer{
   
        svg{
            transform: scale(1.6);
            margin-right: 11px;
        }
    }

    .input-label{
        margin-bottom: 8px;
        color: ${props => props.theme.gray2};
    }

    .select-input__option{
        &:hover{
            cursor: pointer;
        }
    }
    @media (max-width: 500px){
        .css-yk16xz-control, .css-1pahdxg-control{
            height: 55px;
            border-radius: 8px;
        }
        .css-g1d714-ValueContainer{
            flex: 1;
        }
        .css-1hb7zxy-IndicatorsContainer{
            .css-1mbzne4-indicatorContainer, .css-1hkm3ox-indicatorContainer, .css-1f5px50-indicatorContainer {
                width: 100%;
                padding: 0px!important;
            }
            svg{
                transform: scale(1.6);
                margin-right: 6px;
            }
        }
    }
`

//component
const SelectInput = (props) => {
    const [isSelectOpen, setIsSelectOpen] = useState();

    const customStyles = {

        indicatorSeparator: () => ({
            display: "none"
        }),
        dropdownIndicator: (provided) => {
            return ({
                ...provided,
                color: props.awards && isSelectOpen ? props.theme.white : props.awards ? props.theme.white : isSelectOpen ? props.theme.white : props.value !== "" ? props.theme.violet : props.theme.gray1,
                transform: isSelectOpen && "rotate(180deg)",
                marginRight: isSelectOpen && "11px",
                transition: "transform .1s linear, margin-right .1s linear"
            })
        },
        menu: (provided) => {
            return {
                ...provided,
                border: `1.5px solid ${isSelectOpen ? props.theme.white : "transparent"}`,
                borderRadius: "4px",
                backgroundColor: props.theme.black2,
                '&::-webkit-scrollbar': { width: 0, },
                "@media (max-width: 550px)": { paddingLeft: "4px" },
            }
        },
        menuPortal: (provided) => {
            return {
                ...provided,
            }
        },
        input: (provided) => {
            return {
                ...provided,
                fontSize: `16px`,
                fontFamily: `'Raleway', sans-serif`,
                fontWeight: 40,
                caretColor: "transparent",
                color: props.theme.white,
            }
        },
        control: (provided) => {
            return {
                ...provided,
                borderColor: isSelectOpen ? props.theme.white : props.error ? props.theme.textError : (!props.isSelectOpen && props.selectedOption?.value !== null) ? props.theme.violet : "transparent",
                outline: "none",
                backgroundColor: props.inputBackground || props.theme.black2,
                boxShadow: "none"
            }
        },
        singleValue: (provided) => {
            return {
                ...provided,
                paddingLeft: '14px',
                color: props.awards && isSelectOpen ? props.theme.white : props.awards ? props.theme.white : isSelectOpen ? props.theme.white : props.selectedOption.value !== null ? props.theme.violet : props.theme.gray2
            }
        },
        option: (provided, state) => {
            return {
                ...provided,
                width: props.awards && "calc(100% - 24px)",
                fontWeight: props.awards && "700",
                backgroundColor: state.isSelected && props.theme.violet,
                color: state.isSelected ? props.theme.white : props.theme.gray1,
                padding: props.awards ? "24px 0px" : "26px",
                margin: props.awards && "0px 12px",
                borderBottom: props.awards && `1px solid ${props.theme.gray}`
            }
        }
    }

    return (
        <SelectInputWrapper className={props.className} isSelectOpen={isSelectOpen} error={props.error}>
            <p className="input-label" >{props.inputLabel}</p>
            <Select
                defaultValue={props.defaultValue && props.defaultValue}
                value={props.selectedOption}
                onChange={props.onChange}
                onMenuOpen={() => setIsSelectOpen(true)}
                onMenuClose={() => setIsSelectOpen(false)}
                options={props.data}
                isSearchable={true}
                styles={customStyles}
                placeholder={false}
                onFocus={() => props.setIsSelectFocused && props.setIsSelectFocused(true)}
                onBlur={() => props.setIsSelectFocused && props.setIsSelectFocused(false)}
                escapeClearsValue={true}
                menuShouldScrollIntoView={true}
                classNamePrefix="select-input"
            />
        </SelectInputWrapper>

    );
}

export default withTheme(SelectInput);