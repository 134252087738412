import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { TextCustom } from "../../../../Components/Text/Text";
import bgDesktop from "./Static/bg-desktop.png";
import bgMobile from "./Static/bg-mobile.png";
import Wrapper from "../../../../Components/Wrapper/Wrapper";
import theme from "../../../../Consts/Theme";
import ButtonBasic from "../../../../Components/ButtonBasic/ButtonBasic";

// styled-components
const StyledContainer = styled.section`
  padding-top: 160px;
  padding-bottom: 160px;

  @media (max-width: 600px) {
    padding-top: 50px;
  }
`;

const StyledContentBox = styled.section`
  background: ${theme.silverGradient};
  background-image: url(${bgDesktop});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  border-radius: 16px;
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  p.section-title {
    margin-top: 16px;
    margin-bottom: 48px;
  }

  @media (max-width: 600px) {
    background-image: url(${bgMobile});
    height: 350px;

    p.section-title {
      font-size: 25px;
      line-height: 32px;
    }
    p.section-headline {
      font-size: 16px;
      line-height: 20px;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    .section-title {
      margin-top: 16px;
    }
  }

  @media (min-width: 601px) and (max-width: 769px) {
    p.section-title {
      font-size: 40px;
      line-height: 48px;
    }
    p.section-headline {
      font-size: 18px;
    }
  }
`;

const SNPLSection = ({ handleScrollToSection }) => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <Wrapper>
        <StyledContentBox>
          <TextCustom
            fontSize="23"
            lineHeight="32"
            fontWeight={500}
            color={theme.altBlack}
            className="section-headline"
          >
            {t("homepage.snpl_section.headline")}
          </TextCustom>
          <div className="section-title">
            <TextCustom
              fontSize="23"
              lineHeight="32"
              fontWeight={500}
              color={theme.altBlack}
              className="section-headline"
            >
              {t("homepage.snpl_section.second_headline")}
            </TextCustom>
          </div>

          <div style={{ marginTop: "32px" }}>
            <TextCustom
              fontSize="50"
              lineHeight="64"
              fontWeight={500}
              color={theme.black}
              className="section-title"
              dangerouslySetInnerHTML={{
                __html: t(`homepage.snpl_section.section_title`),
              }}
            ></TextCustom>
          </div>
          <ButtonBasic
            variation="extra-large"
            filling={"black"}
            text={t("homepage.greatest_challenge_in_life.cta")}
            onClick={handleScrollToSection}
          />
        </StyledContentBox>
      </Wrapper>
    </StyledContainer>
  );
};

export default SNPLSection;
