import React from "react";
import styled from "styled-components/macro";

import CarouselCardMobile from "./CarouselCardMobile";

// styled-components
const StyledContainer = styled.div`
  padding: 16px;
  background-color: #302f38;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

const TestimonialCarouselMobile = ({ testimonials }) => {
  return (
    <StyledContainer>
      {testimonials.map((testimonial) => (
        <CarouselCardMobile key={testimonial.id} testimonial={testimonial} />
      ))}
    </StyledContainer>
  );
};

export default TestimonialCarouselMobile;
