export const resultsSpinner = <svg width="152" height="153" viewBox="0 0 152 153" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.2242 72.6133L3.4091 69.8988L4.94992 70.3306C4.45686 69.8988 4.02543 69.4052 3.7789 68.9117C3.53237 68.4181 3.4091 67.9246 3.47074 67.4927C3.47074 67.3694 3.47074 67.3077 3.47074 67.246C3.47074 67.1843 3.47074 67.1843 3.47074 67.1226L6.12094 67.8629C6.05931 68.4181 6.12094 68.9117 6.18257 69.4052C6.2442 69.8988 6.4291 70.269 6.73727 70.5774L11.5446 71.9964L11.2981 74.9577L3.2242 72.6133Z" fill="#7497FF" />
    <path d="M12.5307 65.025C12.4075 65.5185 12.2842 66.0121 12.0377 66.3822C11.7911 66.7524 11.483 67.1226 11.1132 67.3693C10.7434 67.6161 10.3736 67.8012 9.88053 67.8629C9.4491 67.9246 8.95604 67.9246 8.40135 67.8629C7.72339 67.7395 7.10706 67.4927 6.55237 67.0609C5.99767 66.6907 5.56625 66.1972 5.19645 65.6419C4.82665 65.0867 4.58012 64.4698 4.45686 63.7294C4.33359 63.0508 4.33359 62.3105 4.45686 61.5702C4.64176 60.5831 5.01155 59.8427 5.50461 59.4109C5.99767 58.9173 6.67563 58.7939 7.41522 58.9173C8.33971 59.1024 8.95604 59.6577 9.2642 60.5831C9.57237 61.5085 9.57237 62.804 9.2642 64.4081L9.20257 64.8399C9.634 64.9016 9.94216 64.9016 10.1887 64.7782C10.3736 64.6548 10.5585 64.4081 10.6201 64.0379C10.7434 63.3593 10.5585 62.804 10.0654 62.3722L11.2364 60.2129C11.5446 60.5214 11.7911 60.8298 11.976 61.2C12.1609 61.5702 12.3458 62.002 12.4691 62.3722C12.5924 62.804 12.654 63.2359 12.654 63.6677C12.654 64.1613 12.5924 64.5931 12.5307 65.025ZM6.49073 62.4956C6.4291 62.804 6.49073 63.1125 6.67563 63.3593C6.86053 63.6677 7.10706 63.9145 7.47686 64.1613V64.0379C7.60012 63.2359 7.66176 62.6806 7.66176 62.3722C7.60012 62.0637 7.47686 61.8786 7.23033 61.8169C7.04543 61.7552 6.92216 61.8169 6.73727 61.9403C6.614 62.0637 6.55237 62.2488 6.49073 62.4956Z" fill="#7497FF" />
    <path d="M14.3797 57.375C14.1948 58.0536 13.8867 58.6089 13.4552 59.1024C13.0238 59.596 12.5924 59.9661 12.0377 60.2129L10.7434 58.3621C11.1748 58.0536 11.6062 57.7452 11.9144 57.4367C12.2226 57.1282 12.4075 56.7581 12.5307 56.3879C12.5924 56.2028 12.5924 56.0177 12.5307 55.956C12.4691 55.8327 12.4075 55.771 12.2226 55.7093C12.0993 55.6476 11.976 55.7093 11.8528 55.771C11.7295 55.8327 11.5446 56.0177 11.2981 56.3262C10.9899 56.6964 10.7434 57.0048 10.5585 57.2516C10.312 57.4984 10.1271 57.6835 9.88053 57.8685C9.634 58.0536 9.4491 58.1153 9.2642 58.1153C9.07931 58.177 8.83278 58.1153 8.58625 58.0536C8.15482 57.9302 7.78502 57.6835 7.47686 57.375C7.16869 57.0665 6.92216 56.6964 6.73727 56.2645C6.55237 55.8327 6.49074 55.3391 6.4291 54.8456C6.4291 54.352 6.49073 53.8585 6.614 53.3649C6.7989 52.8097 7.04543 52.3161 7.35359 51.946C7.66176 51.5141 8.09318 51.2056 8.70951 50.8972L9.94216 52.8714C9.51074 53.1798 9.14094 53.4266 8.95604 53.6734C8.70951 53.9202 8.58625 54.1669 8.46298 54.4137C8.40135 54.5988 8.40135 54.7222 8.46298 54.8456C8.52461 54.969 8.58625 55.0306 8.77114 55.0923C8.89441 55.154 9.01767 55.0923 9.14094 54.969C9.2642 54.8456 9.4491 54.6605 9.634 54.352C10.1887 53.55 10.6818 53.0565 11.0516 52.8097C11.483 52.5629 11.9144 52.5012 12.4075 52.6246C12.8389 52.748 13.2703 52.9948 13.5785 53.3032C13.8867 53.6117 14.1332 53.9819 14.3181 54.352C14.503 54.7839 14.5646 55.2157 14.5646 55.7093C14.6879 56.2645 14.5646 56.8198 14.3797 57.375Z" fill="#7497FF" />
    <path d="M16.5369 51.329C16.2903 51.8843 15.9205 52.2544 15.4891 52.4395C15.0577 52.6246 14.5646 52.5629 13.9483 52.2544C13.7018 52.131 13.3936 51.946 13.0854 51.6992L8.70951 48.306L9.94216 45.6532L13.825 48.6145C13.9483 48.7379 14.0716 48.7996 14.1948 48.8613C14.6262 49.0464 14.9344 48.9847 15.1193 48.6145C15.1809 48.4294 15.2426 48.2444 15.2426 47.9976C15.2426 47.7508 15.1809 47.3806 14.9344 47.0105L10.805 43.8024L12.0993 41.0879L16.1054 44.1726C16.2287 44.296 16.352 44.3577 16.4136 44.3577C16.6601 44.481 16.845 44.4194 16.9683 44.1726L19.0022 45.7149C18.8789 46.2085 18.7556 46.6403 18.5707 46.9488C18.3858 47.3806 18.1393 47.6274 17.8928 47.8125C17.5846 47.9976 17.2764 47.9976 16.9067 47.8125C16.845 47.8125 16.845 47.7508 16.7834 47.7508C16.7218 47.7508 16.7218 47.6891 16.6601 47.6891C16.9067 48.306 17.0299 48.923 17.0299 49.5399C16.9683 50.1569 16.7834 50.7738 16.5369 51.329Z" fill="#7497FF" />
    <path d="M20.8511 43.0004C20.4813 43.5556 20.1116 43.9258 19.6801 44.0492C19.2487 44.1726 18.8173 44.1109 18.3858 43.8024C18.2626 43.7407 18.2009 43.679 18.0777 43.6173C17.9544 43.5556 17.8928 43.494 17.8311 43.3706L11.0516 36.8927L12.5924 34.3633L18.5707 40.1008C18.6324 40.2242 18.7556 40.2859 18.8173 40.3476C19.1254 40.5327 19.4336 40.471 19.6185 40.1625C19.6801 40.0391 19.7418 39.9157 19.7418 39.7923C19.8034 39.669 19.8034 39.4839 19.8034 39.3605L21.5907 40.5944C21.5291 41.0262 21.4058 41.3964 21.2826 41.8899C21.2209 42.3218 21.036 42.6919 20.8511 43.0004Z" fill="#7497FF" />
    <path d="M23.9328 38.3117C23.7479 38.5585 23.563 38.7435 23.3781 38.8669C23.1932 39.052 23.0083 39.1137 22.7618 39.1754C22.5152 39.2371 22.3303 39.2371 22.0838 39.2371C21.8373 39.1754 21.5907 39.052 21.3442 38.8669C21.2826 38.8052 21.1593 38.7435 21.0977 38.6202C21.036 38.5585 20.9128 38.4351 20.8511 38.3734L18.2626 35.4738L17.7079 36.2141L16.1671 34.4867L16.7218 33.7464L14.996 31.7722L16.7834 29.3661L18.5707 31.4637L19.4336 30.2298L20.9744 31.9573L20.0499 33.1911L21.8989 35.2887C21.9605 35.3504 21.9605 35.3504 22.0222 35.4121C22.0838 35.4738 22.0838 35.4738 22.1454 35.4738C22.392 35.6589 22.5769 35.5972 22.7618 35.3504C22.8234 35.227 22.885 35.1036 23.0083 34.9185C23.0699 34.7335 23.1316 34.5484 23.1932 34.3016L25.1038 35.7206C24.9805 36.2141 24.7956 36.646 24.6107 37.1395C24.4258 37.5714 24.1793 38.0032 23.9328 38.3117Z" fill="#7497FF" />
    <path d="M28.0622 33.2528C27.5691 33.7464 27.076 34.1782 26.4597 34.425C25.8434 34.7335 25.2887 34.8569 24.734 34.7952L24.3642 32.5742C24.9189 32.5125 25.3503 32.3891 25.7818 32.204C26.2132 32.019 26.5214 31.7722 26.7679 31.5254C26.8911 31.3403 26.9528 31.2169 26.9528 31.0935C26.9528 30.9702 26.8911 30.8468 26.7679 30.7851C26.7062 30.7234 26.583 30.6617 26.3981 30.7234C26.2132 30.7851 26.0283 30.8468 25.7201 31.0319C25.2887 31.2786 24.9189 31.4637 24.6107 31.5871C24.3026 31.7105 23.9944 31.8339 23.7479 31.8339C23.5014 31.8956 23.2548 31.8956 23.0699 31.8339C22.885 31.7722 22.7001 31.6488 22.5152 31.5254C22.1454 31.2169 21.8989 30.8468 21.7756 30.4149C21.5907 29.9831 21.5291 29.5512 21.5907 29.0577C21.6524 28.5641 21.714 28.1323 21.8989 27.6387C22.0838 27.1452 22.392 26.775 22.7001 26.3431C23.0699 25.9113 23.5014 25.5411 23.9328 25.2944C24.3642 25.0476 24.9189 24.9242 25.5969 24.9242L25.905 27.2069C25.3503 27.3302 24.9805 27.3919 24.6724 27.5153C24.3642 27.6387 24.1177 27.7621 23.9328 28.0089C23.8095 28.1323 23.7479 28.2556 23.7479 28.4407C23.7479 28.5641 23.8095 28.6875 23.9328 28.8109C24.056 28.8726 24.1793 28.9343 24.3026 28.8726C24.4875 28.8109 24.734 28.6875 25.0422 28.5024C25.8434 28.0089 26.5214 27.7621 27.0144 27.7004C27.5075 27.6387 27.9389 27.7621 28.3087 28.1323C28.6785 28.4407 28.925 28.8109 29.1099 29.2427C29.2948 29.6746 29.3565 30.0448 29.2948 30.5383C29.2332 31.0319 29.1716 31.4637 28.925 31.8956C28.7401 32.3274 28.432 32.821 28.0622 33.2528Z" fill="#7497FF" />
    <path d="M29.7879 20.4823V18.9399L28.8018 19.3101L28.5552 17.9528L29.6646 17.7677L28.9867 16.6573L30.1577 15.7319L30.6507 16.9657L31.2671 15.7319L32.1299 16.5956L31.452 17.5827L32.623 18.1996L32.1299 19.4952L30.8973 18.8165L30.9589 20.4206L29.7879 20.4823Z" fill="#7497FF" />
    <path d="M37.4303 14.3746L39.7724 12.9556L40.2654 14.498C40.3271 13.8194 40.4503 13.2024 40.7585 12.7089C41.005 12.2153 41.3748 11.8452 41.7446 11.5984C41.8679 11.5367 41.9295 11.475 41.9295 11.475C41.9911 11.475 41.9911 11.4133 42.0528 11.4133L42.9156 14.0044C42.4842 14.2512 42.0528 14.5597 41.7446 14.9298C41.4364 15.3 41.1899 15.6702 41.0667 16.0403L42.6691 20.7907L40.1422 22.3331L37.4303 14.3746Z" fill="#7497FF" />
    <path d="M48.9556 17.6444C48.4626 17.8911 47.9695 18.0145 47.5381 18.0145C47.045 18.0145 46.6136 17.9528 46.2438 17.8294C45.8124 17.706 45.4426 17.4593 45.1344 17.1508C44.8262 16.8423 44.5181 16.4105 44.3332 15.9169C44.025 15.3 43.9018 14.6214 43.9018 13.9427C43.9018 13.2641 44.025 12.5855 44.2715 11.9685C44.5181 11.3516 44.8879 10.7964 45.3809 10.2411C45.874 9.74758 46.4903 9.31573 47.1683 9.00726C48.0928 8.5754 48.894 8.45202 49.572 8.6371C50.2499 8.82218 50.743 9.19234 51.0511 9.87097C51.4209 10.7347 51.3593 11.5367 50.743 12.3387C50.1883 13.1407 49.0789 13.8194 47.5997 14.5597L47.2299 14.8065C47.4148 15.2383 47.5997 15.4851 47.8462 15.6085C48.0311 15.7319 48.3393 15.6702 48.6475 15.5468C49.2638 15.3 49.6336 14.8065 49.6952 14.1278L52.1605 13.881C52.0989 14.2512 51.9756 14.6831 51.7907 15.0532C51.6058 15.4234 51.3593 15.7935 51.1128 16.1637C50.8662 16.5339 50.4964 16.8423 50.1883 17.0891C49.7569 17.2742 49.3871 17.4593 48.9556 17.6444ZM47.5997 11.2282C47.3532 11.3516 47.1067 11.5984 46.9834 11.9069C46.8601 12.2153 46.7985 12.5855 46.7985 13.0173L46.9218 12.9556C47.6613 12.6472 48.1544 12.3387 48.4009 12.1536C48.6475 11.9069 48.7091 11.7218 48.5858 11.475C48.5242 11.2899 48.4009 11.2282 48.216 11.1665C48.0311 11.1048 47.7846 11.1048 47.5997 11.2282Z" fill="#7497FF" />
    <path d="M56.3516 14.7448C55.6736 14.9298 55.0573 15.0532 54.3793 14.9915C53.7013 14.9298 53.1467 14.7448 52.6536 14.4363L53.4548 12.3387C53.9479 12.5238 54.4409 12.6472 54.8724 12.7089C55.3038 12.7706 55.7352 12.7706 56.0434 12.6472C56.2283 12.5855 56.3516 12.5238 56.4132 12.4004C56.4748 12.277 56.4748 12.1536 56.4132 12.0302C56.3516 11.9069 56.2899 11.8452 56.1667 11.7835C56.0434 11.7218 55.7352 11.6601 55.4271 11.6601C54.934 11.6601 54.5642 11.5984 54.1944 11.5984C53.8246 11.5984 53.5781 11.475 53.3316 11.4133C53.085 11.3516 52.9001 11.2282 52.7769 11.0431C52.6536 10.9198 52.5303 10.673 52.4687 10.4879C52.3454 10.056 52.2838 9.62419 52.4071 9.19234C52.4687 8.76048 52.6536 8.32863 52.9001 7.95847C53.1467 7.58831 53.4548 7.21815 53.8862 6.90968C54.256 6.60121 54.7491 6.35444 55.2422 6.23105C55.7969 6.04597 56.3515 5.98427 56.8446 5.98427C57.3377 5.98427 57.8924 6.16936 58.4471 6.47782L57.5842 8.6371C57.0911 8.45202 56.6597 8.32863 56.3516 8.26694C56.0434 8.20524 55.7352 8.20524 55.4887 8.32863C55.3038 8.39032 55.1805 8.45202 55.1189 8.5754C54.934 8.6371 54.934 8.76048 54.9956 8.88387C55.0573 9.00726 55.1189 9.06895 55.3038 9.13065C55.4887 9.19234 55.7352 9.19234 56.105 9.19234C57.0295 9.19234 57.7691 9.25403 58.2005 9.50081C58.632 9.68589 58.9401 10.056 59.125 10.5496C59.2483 10.9815 59.3099 11.475 59.2483 11.9069C59.1867 12.3387 59.0018 12.7706 58.8169 13.1407C58.5703 13.5109 58.2622 13.8194 57.8307 14.1278C57.3993 14.4363 56.9062 14.6214 56.3516 14.7448Z" fill="#7497FF" />
    <path d="M62.4532 13.079C61.8369 13.2024 61.3438 13.079 60.974 12.8323C60.6042 12.5855 60.3577 12.0919 60.2344 11.475C60.1728 11.1665 60.1728 10.8581 60.1728 10.4262L60.3577 4.87379L63.2544 4.38024L63.0695 9.25403C63.0695 9.43911 63.0695 9.5625 63.0695 9.68589C63.1311 10.1794 63.3777 10.3645 63.8091 10.3028C63.994 10.3028 64.1789 10.1794 64.3638 10.056C64.5487 9.93266 64.7952 9.62419 65.0418 9.25403L65.2267 4.07177L68.185 3.57823L68.0001 8.6371C68.0001 8.82218 68.0001 8.94556 68.0001 9.00726C68.0618 9.25403 68.185 9.37742 68.4932 9.31573L68.3699 11.9069C67.8769 12.0919 67.4454 12.2153 67.1373 12.277C66.7058 12.3387 66.336 12.3387 66.0279 12.2153C65.7197 12.0919 65.5348 11.8452 65.4732 11.4133C65.4732 11.3516 65.4732 11.2899 65.4732 11.2899C65.4732 11.2282 65.4732 11.1665 65.4732 11.1665C65.1034 11.7218 64.672 12.2153 64.1789 12.5238C63.6858 12.7089 63.0695 12.9556 62.4532 13.079Z" fill="#7497FF" />
    <path d="M71.7597 11.7835C71.0818 11.8452 70.5887 11.7218 70.2189 11.475C69.8491 11.2282 69.6642 10.7964 69.6642 10.2411C69.6642 10.1177 69.6642 9.99435 69.6642 9.87097C69.6642 9.74758 69.6642 9.62419 69.7258 9.50081L71.0818 0.185081L74.0401 0L72.8075 8.26693C72.8075 8.39032 72.7458 8.51371 72.8075 8.6371C72.8075 9.00726 73.054 9.19234 73.4238 9.19234C73.5471 9.19234 73.6703 9.13064 73.7936 9.13064C73.9169 9.06895 74.1018 9.00726 74.225 8.94556L74.2867 11.1665C73.9169 11.3516 73.4854 11.5367 73.054 11.6601C72.5609 11.6601 72.1295 11.7218 71.7597 11.7835Z" fill="#7497FF" />
    <path d="M77.3683 11.5984C77.0601 11.5984 76.8136 11.5367 76.5671 11.475C76.3205 11.4133 76.1356 11.2899 75.9507 11.1665C75.7658 11.0431 75.6426 10.8581 75.5193 10.6113C75.396 10.4262 75.396 10.1177 75.396 9.80927C75.396 9.68589 75.396 9.5625 75.396 9.43911C75.396 9.31573 75.4577 9.19234 75.4577 9.06895L76.3205 5.30565H75.396L75.9507 3.08468H76.8752L77.4915 0.493548L80.4499 0.555242L79.8336 3.14637L81.3128 3.20806L80.8813 5.55242L79.3405 5.49073L78.7242 8.20524C78.7242 8.26694 78.7242 8.32863 78.7242 8.32863C78.7242 8.39032 78.7242 8.39032 78.7242 8.45202C78.7242 8.76048 78.8475 8.88387 79.1556 8.94556C79.2789 8.94556 79.4638 8.94556 79.6487 8.88387C79.8336 8.82218 80.0185 8.76048 80.265 8.69879L80.2034 11.0431C79.7103 11.2282 79.2789 11.3516 78.7858 11.475C78.2928 11.5984 77.7997 11.5984 77.3683 11.5984Z" fill="#7497FF" />
    <path d="M83.7781 11.9686C83.3467 11.9069 82.9152 11.7835 82.6071 11.5367C82.2989 11.3516 81.9907 11.0431 81.8058 10.7347C81.6209 10.4262 81.436 10.0561 81.3744 9.6242C81.3128 9.19234 81.3128 8.76049 81.3744 8.26694C81.4977 7.58831 81.6826 6.90968 81.9907 6.35444C82.2989 5.79919 82.7303 5.24395 83.2234 4.87379C83.7164 4.44194 84.2095 4.13347 84.7642 3.94839C85.3189 3.76331 85.9352 3.70161 86.4899 3.76331C86.983 3.825 87.4144 4.01008 87.7226 4.31855C88.0307 4.62702 88.2156 4.99718 88.2773 5.42903L88.7087 4.31855L91.6671 4.7504L89.9414 9.43911C89.8797 9.5625 89.8797 9.68589 89.8181 9.80928C89.7565 10.0561 89.8797 10.2411 90.1879 10.3028L89.325 12.7089C88.7703 12.7089 88.3389 12.7089 88.0307 12.7089C87.5377 12.6472 87.2295 12.5238 86.983 12.277C86.7365 12.0919 86.6748 11.7218 86.7365 11.2899C86.7365 11.1665 86.7981 11.1048 86.7981 11.0431C86.3667 11.475 85.8736 11.7835 85.3189 11.9686C84.7642 12.0302 84.2712 12.0302 83.7781 11.9686ZM85.4422 9.68589C85.6887 9.74758 85.9352 9.68589 86.1818 9.5625C86.4283 9.43911 86.6748 9.25403 86.983 9.06895L87.476 7.65C87.476 7.27984 87.4144 6.97137 87.2295 6.7246C87.0446 6.47782 86.8597 6.35444 86.5516 6.29274C86.305 6.23105 86.1201 6.29274 85.8736 6.41613C85.6271 6.53952 85.4422 6.6629 85.2573 6.84799C85.0724 7.03307 84.8875 7.21815 84.7642 7.52661C84.6409 7.77339 84.5177 8.08186 84.5177 8.32863C84.456 8.69879 84.5177 9.06895 84.6409 9.31573C84.8258 9.50081 85.134 9.62419 85.4422 9.68589Z" fill="#7497FF" />
    <path d="M93.3311 13.881C92.6532 13.696 92.0985 13.3875 91.5438 13.0173C91.0507 12.6472 90.6193 12.1536 90.4344 11.5984L92.2218 10.2411C92.5299 10.673 92.8381 11.0431 93.2079 11.3516C93.5777 11.6601 93.8858 11.8452 94.2556 11.9685C94.4405 12.0302 94.6254 12.0302 94.6871 11.9685C94.8103 11.9069 94.872 11.7835 94.872 11.6601C94.9336 11.5367 94.872 11.4133 94.8103 11.2899C94.7487 11.1665 94.5022 10.9815 94.2556 10.7964C93.8858 10.5496 93.516 10.3028 93.2695 10.056C93.023 9.80927 92.7764 9.62419 92.6532 9.37742C92.5299 9.19234 92.4066 8.94556 92.345 8.76048C92.2834 8.5754 92.345 8.32863 92.4067 8.08185C92.5299 7.65 92.7764 7.27984 93.0846 6.90968C93.3928 6.60121 93.7626 6.29274 94.194 6.10766C94.6254 5.92258 95.0569 5.79919 95.5499 5.79919C96.043 5.79919 96.536 5.79919 97.0907 5.98427C97.6454 6.16935 98.1385 6.35444 98.5699 6.6629C99.0013 6.97137 99.3711 7.40323 99.6177 7.95847L97.7071 9.25403C97.3989 8.82218 97.0907 8.51371 96.8442 8.26694C96.5977 8.02016 96.3511 7.89677 96.1046 7.83508C95.9197 7.77339 95.7965 7.77339 95.6732 7.83508C95.5499 7.89677 95.4883 8.02016 95.4267 8.14355C95.365 8.26694 95.4266 8.39032 95.5499 8.51371C95.6732 8.6371 95.8581 8.82218 96.1662 9.00726C96.9675 9.50081 97.5222 9.99435 97.7687 10.4262C98.0769 10.8581 98.1385 11.2899 97.9536 11.7835C97.8303 12.277 97.5838 12.6472 97.3373 12.9556C97.0291 13.2641 96.7209 13.5109 96.2895 13.696C95.8581 13.881 95.4266 13.9427 94.9336 14.0044C94.4405 14.0661 93.9475 14.0044 93.3311 13.881Z" fill="#7497FF" />
    <path d="M105.534 9.74758L106.952 9.06895L106.151 8.32863L107.198 7.46492L107.876 8.39032L108.554 7.27984L109.91 7.89677L109.047 8.88387H110.403L109.972 10.056L108.801 9.87097L108.739 11.1665L107.322 11.2899L107.445 9.93266L106.027 10.7347L105.534 9.74758Z" fill="#7497FF" />
    <path d="M114.41 13.8194L116.69 15.3L115.581 16.4105C116.197 16.1637 116.813 16.0403 117.368 16.0403C117.923 16.0403 118.416 16.1637 118.785 16.4105C118.909 16.4722 118.97 16.5339 118.97 16.5339C119.032 16.5339 119.032 16.5956 119.032 16.5956L117.121 18.5698C116.69 18.2613 116.197 18.0762 115.765 17.8911C115.272 17.7677 114.903 17.706 114.471 17.8294L110.958 21.4077L108.431 19.8036L114.41 13.8194Z" fill="#7497FF" />
    <path d="M116.628 25.6645C116.197 25.356 115.889 24.9859 115.642 24.554C115.396 24.1222 115.272 23.752 115.211 23.3202C115.149 22.8883 115.211 22.4565 115.334 22.0246C115.457 21.5927 115.704 21.1609 116.074 20.729C116.505 20.1738 117.06 19.7419 117.614 19.4335C118.231 19.125 118.847 18.9399 119.525 18.9399C120.203 18.8782 120.881 19.0016 121.559 19.1867C122.237 19.3718 122.853 19.7419 123.47 20.2355C124.271 20.8524 124.702 21.531 124.887 22.2097C125.072 22.8883 124.887 23.5052 124.456 24.1222C123.901 24.8625 123.1 25.1093 122.114 24.9242C121.127 24.7391 120.018 24.1222 118.724 23.0734L118.354 22.7649C118.046 23.1351 117.923 23.3819 117.923 23.6286C117.923 23.8754 118.046 24.1222 118.354 24.3073C118.847 24.7391 119.463 24.8625 120.08 24.6157L121.436 26.6516C121.066 26.775 120.634 26.8367 120.203 26.8367C119.772 26.8367 119.34 26.775 118.909 26.7133C118.477 26.5899 118.046 26.4665 117.676 26.2815C117.306 26.1581 116.998 25.9113 116.628 25.6645ZM121.744 21.531C121.497 21.346 121.189 21.2843 120.881 21.2843C120.511 21.2843 120.141 21.4077 119.772 21.5927L119.833 21.6544C120.45 22.148 120.943 22.4565 121.251 22.5798C121.559 22.7032 121.805 22.6415 121.99 22.4565C122.114 22.3331 122.114 22.148 122.114 21.9629C122.052 21.8395 121.929 21.6544 121.744 21.531Z" fill="#7497FF" />
    <path d="M122.483 30.9085C121.99 30.4149 121.621 29.8597 121.374 29.2427C121.127 28.6258 121.004 28.0706 121.066 27.4536L123.285 27.2069C123.346 27.7621 123.408 28.2556 123.593 28.6875C123.716 29.1194 123.963 29.4895 124.209 29.7363C124.332 29.8597 124.456 29.9831 124.579 29.9831C124.702 29.9831 124.825 29.9214 124.887 29.8597C124.949 29.798 125.01 29.6746 125.01 29.4895C125.01 29.3044 124.887 29.0577 124.764 28.7492C124.579 28.3173 124.394 27.9472 124.271 27.6387C124.147 27.3302 124.086 27.0218 124.086 26.775C124.086 26.5282 124.086 26.2814 124.147 26.0964C124.209 25.9113 124.332 25.7262 124.517 25.5411C124.825 25.2327 125.257 24.9859 125.688 24.8625C126.12 24.7391 126.551 24.6774 127.044 24.7391C127.476 24.8008 127.969 24.9242 128.4 25.171C128.832 25.4177 129.263 25.6645 129.633 26.0964C130.064 26.5282 130.372 26.9601 130.557 27.3919C130.804 27.8238 130.865 28.379 130.865 29.0577L128.523 29.2427C128.462 28.6875 128.4 28.3173 128.277 27.9472C128.154 27.6387 128.03 27.3919 127.845 27.2069C127.722 27.0835 127.599 27.0218 127.476 27.0218C127.352 27.0218 127.229 27.0835 127.106 27.1452C126.983 27.2685 126.983 27.3919 126.983 27.5153C127.044 27.7004 127.106 27.9472 127.291 28.2556C127.722 29.1194 127.969 29.798 127.969 30.2915C128.03 30.7851 127.845 31.2169 127.476 31.5871C127.106 31.8956 126.736 32.1423 126.305 32.3274C125.873 32.4508 125.442 32.5125 125.01 32.4508C124.579 32.3891 124.147 32.2657 123.654 32.019C123.346 31.6488 122.915 31.3403 122.483 30.9085Z" fill="#7497FF" />
    <path d="M126.798 35.5972C126.428 35.1036 126.243 34.6101 126.366 34.1782C126.428 33.7464 126.736 33.3145 127.229 32.9444C127.476 32.7593 127.784 32.5742 128.154 32.4508L133.207 30.1681L134.995 32.5742L130.619 34.6101C130.496 34.6718 130.372 34.7335 130.249 34.7952C129.879 35.1036 129.818 35.4121 130.003 35.7206C130.126 35.844 130.249 35.9673 130.496 36.0907C130.742 36.2141 131.05 36.2758 131.543 36.3375L136.227 34.1782L138.015 36.5843L133.392 38.6819C133.269 38.7435 133.146 38.8052 133.084 38.8669C132.899 39.052 132.838 39.2371 133.023 39.4222L130.681 40.471C130.311 40.1008 130.003 39.7923 129.818 39.5456C129.571 39.1754 129.386 38.8669 129.386 38.4968C129.325 38.1883 129.51 37.8798 129.818 37.6331C129.879 37.6331 129.879 37.5714 129.941 37.5714C130.003 37.5714 130.003 37.5097 130.064 37.5097C129.386 37.448 128.77 37.2629 128.277 36.9544C127.599 36.5226 127.167 36.1524 126.798 35.5972Z" fill="#7497FF" />
    <path d="M132.098 43.3706C131.79 42.8153 131.667 42.3218 131.728 41.8899C131.79 41.4581 132.098 41.0879 132.529 40.8411C132.653 40.7794 132.714 40.7177 132.838 40.656C132.961 40.5944 133.084 40.5944 133.207 40.5327L142.144 37.6331L143.623 40.2242L135.673 42.8153C135.55 42.877 135.426 42.877 135.365 42.9387C134.995 43.1238 134.933 43.3706 135.118 43.7407C135.18 43.8641 135.241 43.9258 135.365 44.0492C135.488 44.1726 135.611 44.2343 135.734 44.3577L133.762 45.4064C133.454 45.1597 133.084 44.8512 132.776 44.5427C132.53 44.0492 132.283 43.679 132.098 43.3706Z" fill="#7497FF" />
    <path d="M134.748 48.3061C134.625 48.0593 134.563 47.8125 134.502 47.5657C134.44 47.319 134.44 47.0722 134.502 46.8871C134.563 46.702 134.625 46.4552 134.81 46.2702C134.995 46.0851 135.18 45.9617 135.426 45.7766C135.55 45.7149 135.611 45.6532 135.734 45.6532C135.858 45.6532 135.981 45.5915 136.104 45.5298L139.864 44.6661L139.432 43.8024L141.651 43.3089L142.083 44.1726L144.671 43.5556L145.965 46.2702L143.377 46.8871L143.993 48.2444L141.774 48.7996L141.158 47.4423L138.446 48.0593C138.385 48.0593 138.323 48.0593 138.323 48.121C138.261 48.121 138.261 48.1827 138.2 48.1827C137.953 48.3061 137.83 48.4911 138.015 48.7996C138.076 48.923 138.138 49.0464 138.261 49.2315C138.385 49.4165 138.508 49.5399 138.693 49.725L136.597 50.7121C136.227 50.3419 135.919 50.0335 135.611 49.6016C135.18 49.1081 134.933 48.7379 134.748 48.3061Z" fill="#7497FF" />
    <path d="M137.275 54.352C137.029 53.6734 136.967 53.0565 136.967 52.3778C136.967 51.6992 137.152 51.144 137.46 50.6504L139.617 51.3907C139.432 51.8843 139.309 52.3778 139.247 52.8097C139.186 53.2415 139.247 53.6734 139.371 53.9819C139.432 54.1669 139.556 54.2903 139.617 54.352C139.741 54.4137 139.864 54.4137 139.987 54.352C140.11 54.2903 140.172 54.2286 140.234 54.0435C140.295 53.9202 140.295 53.6117 140.295 53.3032C140.295 52.8097 140.295 52.4395 140.357 52.0694C140.419 51.6992 140.418 51.4524 140.542 51.2056C140.603 50.9589 140.727 50.7738 140.912 50.5887C141.035 50.4653 141.22 50.3419 141.466 50.2185C141.898 50.0952 142.329 50.0335 142.761 50.0952C143.192 50.1569 143.623 50.2802 144.055 50.527C144.425 50.7738 144.794 51.0823 145.103 51.4524C145.411 51.8226 145.657 52.2544 145.842 52.8097C146.027 53.3649 146.15 53.9202 146.15 54.4137C146.15 54.9073 146.027 55.4625 145.719 56.0177L143.562 55.2157C143.747 54.7222 143.808 54.2903 143.87 53.9819C143.932 53.6734 143.87 53.3649 143.808 53.1181C143.747 52.9331 143.685 52.8097 143.562 52.748C143.439 52.6863 143.315 52.6863 143.192 52.748C143.069 52.8097 143.007 52.8714 142.945 53.0564C142.884 53.2415 142.884 53.4883 142.884 53.8585C142.945 54.7839 142.884 55.5242 142.699 55.956C142.514 56.4496 142.206 56.7581 141.713 56.8815C141.281 57.0665 140.788 57.0665 140.357 57.0665C139.925 57.0665 139.494 56.8815 139.124 56.6347C138.754 56.3879 138.385 56.0794 138.076 55.7093C137.707 55.3391 137.46 54.9073 137.275 54.352Z" fill="#7497FF" />
    <path d="M147.691 61.9403L149.047 62.6806L149.17 61.6319L150.465 62.002L150.095 63.1125L151.389 63.0508L151.636 64.5315L150.28 64.4081L151.081 65.5185L149.91 65.827L149.355 64.7782L148.246 65.4569L147.321 64.4081L148.492 63.7294L147.013 62.9891L147.691 61.9403Z" fill="#7497FF" />
    <path d="M149.417 71.5645L149.54 74.279L147.938 74.0323C148.492 74.4024 148.924 74.8343 149.294 75.2661C149.602 75.698 149.787 76.1915 149.787 76.6851C149.787 76.8085 149.787 76.8702 149.787 76.9319C149.787 76.9935 149.787 76.9936 149.787 77.0552L147.075 76.6234C147.075 76.0681 146.952 75.5746 146.828 75.1427C146.705 74.6492 146.458 74.3407 146.15 74.0323L141.22 73.2302L141.096 70.269L149.417 71.5645Z" fill="#7497FF" />
    <path d="M141.096 80.2016C141.158 79.6464 141.22 79.2145 141.466 78.7827C141.651 78.3508 141.898 77.9806 142.267 77.7339C142.576 77.4254 142.945 77.2403 143.439 77.1169C143.87 76.9935 144.363 76.9319 144.918 76.9935C145.596 77.0552 146.274 77.2403 146.828 77.5488C147.445 77.8573 147.938 78.2891 148.369 78.8444C148.801 79.3379 149.109 79.9548 149.355 80.6335C149.54 81.3121 149.663 82.0524 149.602 82.7927C149.54 83.7798 149.294 84.5818 148.801 85.0754C148.369 85.6306 147.753 85.8157 147.013 85.8157C146.089 85.754 145.411 85.2605 144.979 84.3968C144.548 83.5331 144.425 82.2375 144.548 80.5718V80.1399C144.116 80.1399 143.808 80.1399 143.562 80.325C143.377 80.4484 143.254 80.6952 143.254 81.0653C143.192 81.7439 143.439 82.2375 143.993 82.6693L143.069 84.952C142.761 84.7052 142.452 84.3968 142.206 84.0266C141.959 83.6564 141.774 83.2863 141.59 82.9161C141.405 82.4843 141.281 82.1141 141.22 81.6823C141.096 81.0653 141.035 80.6335 141.096 80.2016ZM147.383 81.9907C147.383 81.6823 147.321 81.3738 147.075 81.127C146.828 80.8802 146.582 80.6335 146.15 80.4484V80.5718C146.089 81.3738 146.089 81.929 146.212 82.2375C146.274 82.546 146.458 82.731 146.705 82.731C146.89 82.731 147.013 82.6694 147.136 82.546C147.321 82.4226 147.383 82.2375 147.383 81.9907Z" fill="#7497FF" />
    <path d="M140.172 88.0367C140.295 87.3581 140.542 86.7411 140.912 86.1859C141.281 85.6306 141.713 85.1988 142.206 84.952L143.685 86.6177C143.254 86.9879 142.945 87.2964 142.637 87.6665C142.391 88.0367 142.206 88.4069 142.144 88.777C142.083 88.9621 142.144 89.1472 142.206 89.2089C142.267 89.3323 142.391 89.394 142.514 89.394C142.637 89.394 142.761 89.3939 142.884 89.2706C143.007 89.1472 143.13 88.9621 143.315 88.6536C143.562 88.2218 143.747 87.9133 143.932 87.6048C144.116 87.2964 144.363 87.1113 144.548 86.9262C144.733 86.7411 144.918 86.6177 145.164 86.5561C145.349 86.4944 145.596 86.4944 145.842 86.5561C146.274 86.6177 146.705 86.8028 147.075 87.1113C147.445 87.3581 147.691 87.7282 147.938 88.1601C148.184 88.5919 148.307 89.0238 148.369 89.5173C148.431 90.0109 148.431 90.5044 148.307 91.0597C148.184 91.6149 147.999 92.1702 147.753 92.602C147.506 93.0339 147.136 93.4657 146.52 93.7742L145.041 91.9851C145.411 91.6149 145.719 91.3065 145.965 91.0597C146.15 90.8129 146.274 90.5661 146.335 90.2577C146.397 90.0726 146.335 89.9492 146.274 89.8258C146.212 89.7024 146.089 89.6407 145.965 89.579C145.842 89.579 145.719 89.579 145.596 89.7024C145.472 89.8258 145.349 90.0726 145.164 90.3811C144.733 91.2448 144.301 91.8 143.932 92.1085C143.562 92.4169 143.069 92.5403 142.576 92.4169C142.083 92.3552 141.713 92.1702 141.343 91.8617C140.973 91.5532 140.727 91.3065 140.48 90.8746C140.295 90.5044 140.11 90.0109 140.049 89.5173C139.987 89.0238 140.049 88.5919 140.172 88.0367Z" fill="#7497FF" />
    <path d="M138.754 94.206C138.939 93.5891 139.247 93.2189 139.679 92.9722C140.11 92.7871 140.603 92.7871 141.22 92.9722C141.466 93.0339 141.836 93.2189 142.144 93.404L146.952 96.1802L146.027 99.0182L141.836 96.5504C141.713 96.4887 141.589 96.427 141.466 96.3653C141.035 96.2419 140.727 96.3036 140.603 96.6738C140.542 96.8589 140.542 97.044 140.542 97.2907C140.542 97.5375 140.727 97.846 140.973 98.2778L145.411 100.869L144.486 103.707L140.11 101.177C139.987 101.116 139.864 101.054 139.741 100.992C139.494 100.931 139.309 100.992 139.247 101.239L137.029 99.9436C137.09 99.3883 137.152 99.0181 137.275 98.648C137.398 98.2161 137.645 97.9077 137.892 97.6609C138.138 97.4141 138.446 97.4141 138.878 97.5375C138.939 97.5375 139.001 97.5992 139.001 97.5992C139.063 97.5992 139.063 97.6609 139.124 97.6609C138.816 97.044 138.57 96.427 138.57 95.8718C138.508 95.4399 138.57 94.8847 138.754 94.206Z" fill="#7497FF" />
    <path d="M135.488 103.028C135.734 102.411 136.104 102.041 136.474 101.856C136.844 101.671 137.337 101.671 137.83 101.918C137.953 101.979 138.015 102.041 138.138 102.103C138.261 102.165 138.323 102.226 138.446 102.288L145.965 107.902L144.733 110.617L138.076 105.619C137.953 105.558 137.892 105.496 137.768 105.434C137.398 105.249 137.152 105.373 136.967 105.681C136.905 105.804 136.905 105.928 136.844 106.051C136.844 106.175 136.844 106.36 136.844 106.483L134.872 105.496C134.872 105.064 134.933 104.632 135.056 104.2C135.18 103.769 135.365 103.398 135.488 103.028Z" fill="#7497FF" />
    <path d="M132.961 108.025C133.084 107.779 133.269 107.594 133.454 107.408C133.639 107.223 133.824 107.1 134.009 106.977C134.194 106.915 134.44 106.853 134.687 106.853C134.933 106.853 135.18 106.977 135.426 107.1C135.55 107.162 135.611 107.223 135.734 107.285C135.858 107.347 135.919 107.408 136.043 107.532L138.939 110.123L139.432 109.321L141.158 110.863L140.603 111.665L142.576 113.393L141.096 115.984L139.124 114.256L138.385 115.552L136.659 114.01L137.398 112.714L135.303 110.863C135.241 110.802 135.241 110.802 135.18 110.74C135.118 110.74 135.118 110.678 135.056 110.678C134.81 110.555 134.563 110.617 134.44 110.863C134.378 110.987 134.317 111.11 134.255 111.295C134.194 111.48 134.132 111.665 134.132 111.912L132.098 110.74C132.16 110.246 132.283 109.753 132.406 109.259C132.591 108.827 132.776 108.396 132.961 108.025Z" fill="#7497FF" />
    <path d="M129.571 113.516C129.818 113.146 130.126 112.838 130.496 112.652C130.865 112.467 131.174 112.344 131.605 112.344C131.975 112.344 132.406 112.344 132.776 112.529C133.207 112.652 133.577 112.899 133.947 113.146C134.502 113.578 134.995 114.071 135.365 114.627C135.734 115.182 135.981 115.799 136.104 116.416C136.227 117.033 136.289 117.65 136.166 118.205C136.043 118.76 135.858 119.315 135.488 119.809C135.18 120.241 134.81 120.487 134.44 120.611C134.009 120.734 133.639 120.734 133.207 120.611L134.009 121.475L132.221 123.881L128.955 120.117C128.832 119.994 128.77 119.932 128.708 119.871C128.523 119.685 128.277 119.747 128.154 119.994L126.49 118.081C126.736 117.588 126.921 117.218 127.106 116.971C127.414 116.601 127.661 116.354 127.969 116.231C128.277 116.107 128.585 116.169 128.955 116.477C129.016 116.539 129.078 116.601 129.14 116.601C128.955 115.984 128.955 115.429 129.016 114.873C129.078 114.442 129.263 113.948 129.571 113.516ZM130.804 116.107C130.681 116.292 130.557 116.539 130.557 116.786C130.557 117.033 130.557 117.341 130.619 117.773L131.605 118.883C131.913 119.069 132.221 119.13 132.529 119.13C132.838 119.13 133.084 118.945 133.207 118.76C133.331 118.575 133.392 118.39 133.454 118.143C133.454 117.896 133.454 117.65 133.392 117.403C133.331 117.156 133.207 116.909 133.084 116.663C132.899 116.416 132.714 116.231 132.468 116.046C132.16 115.799 131.852 115.675 131.543 115.737C131.297 115.675 130.989 115.799 130.804 116.107Z" fill="#7497FF" />
    <path d="M123.408 121.104C123.901 120.611 124.394 120.241 125.01 119.932C125.627 119.685 126.181 119.562 126.736 119.624L127.044 121.845C126.49 121.906 126.058 122.03 125.627 122.153C125.195 122.338 124.887 122.523 124.641 122.77C124.517 122.894 124.394 123.079 124.456 123.202C124.517 123.325 124.517 123.449 124.641 123.51C124.702 123.572 124.825 123.634 125.01 123.634C125.195 123.634 125.38 123.51 125.75 123.387C126.181 123.14 126.551 123.017 126.859 122.894C127.167 122.77 127.476 122.708 127.722 122.647C127.969 122.585 128.215 122.647 128.4 122.708C128.585 122.77 128.77 122.894 128.955 123.079C129.263 123.387 129.51 123.757 129.694 124.189C129.818 124.621 129.879 125.053 129.879 125.546C129.818 126.04 129.756 126.472 129.51 126.904C129.325 127.335 129.016 127.767 128.647 128.137C128.215 128.569 127.784 128.878 127.352 129.125C126.921 129.371 126.366 129.495 125.688 129.433L125.442 127.15C125.996 127.089 126.366 126.965 126.674 126.842C126.983 126.719 127.229 126.595 127.414 126.41C127.537 126.287 127.599 126.163 127.599 126.04C127.599 125.917 127.537 125.793 127.414 125.67C127.291 125.546 127.167 125.546 127.044 125.608C126.859 125.67 126.613 125.731 126.305 125.917C125.503 126.41 124.825 126.657 124.332 126.657C123.839 126.719 123.408 126.533 123.038 126.225C122.668 125.917 122.422 125.546 122.298 125.115C122.114 124.683 122.114 124.251 122.114 123.819C122.175 123.387 122.298 122.955 122.483 122.462C122.668 121.968 123.038 121.536 123.408 121.104Z" fill="#7497FF" />
    <path d="M121.312 133.813L121.251 135.356L122.237 134.985L122.483 136.343L121.374 136.528L121.99 137.638L120.819 138.564L120.326 137.33L119.71 138.502L118.909 137.638L119.648 136.713L118.539 136.034L119.094 134.739L120.265 135.479V133.875L121.312 133.813Z" fill="#7497FF" />
    <path d="M113.485 139.674L111.081 141.031L110.65 139.489C110.588 140.168 110.403 140.723 110.157 141.217C109.91 141.71 109.541 142.08 109.109 142.327C108.986 142.389 108.924 142.45 108.924 142.45C108.863 142.45 108.863 142.512 108.801 142.512L108 139.921C108.493 139.674 108.863 139.366 109.232 138.996C109.602 138.625 109.787 138.317 109.91 137.885L108.431 133.135L111.02 131.716L113.485 139.674Z" fill="#7497FF" />
    <path d="M102.021 136.034C102.514 135.849 103.007 135.726 103.439 135.726C103.932 135.726 104.363 135.787 104.733 135.973C105.165 136.158 105.473 136.404 105.843 136.713C106.212 137.021 106.397 137.453 106.644 137.947C106.89 138.564 107.014 139.242 107.014 139.921C107.014 140.6 106.829 141.217 106.582 141.833C106.336 142.45 105.904 143.006 105.411 143.499C104.918 143.993 104.302 144.363 103.624 144.671C102.699 145.042 101.898 145.165 101.22 144.98C100.542 144.795 100.049 144.363 99.7409 143.684C99.3711 142.821 99.4944 142.019 100.111 141.278C100.727 140.538 101.775 139.798 103.316 139.181L103.747 138.996C103.562 138.564 103.377 138.317 103.192 138.194C103.007 138.07 102.699 138.07 102.391 138.255C101.775 138.502 101.405 138.996 101.282 139.613L98.8164 139.798C98.8781 139.427 99.063 138.996 99.2479 138.625C99.4328 138.255 99.6793 137.885 99.9875 137.577C100.296 137.268 100.604 136.96 100.974 136.713C101.282 136.466 101.652 136.219 102.021 136.034ZM103.254 142.512C103.562 142.389 103.747 142.204 103.87 141.895C103.994 141.587 104.117 141.217 104.117 140.785L103.994 140.846C103.254 141.155 102.761 141.402 102.514 141.648C102.268 141.833 102.206 142.08 102.268 142.327C102.33 142.512 102.453 142.574 102.638 142.635C102.823 142.697 103.007 142.635 103.254 142.512Z" fill="#7497FF" />
    <path d="M94.6254 138.749C95.3034 138.564 95.9197 138.502 96.5977 138.564C97.2756 138.625 97.8303 138.81 98.2618 139.181L97.3989 141.278C96.9058 141.031 96.4128 140.908 95.9813 140.846C95.5499 140.785 95.1185 140.785 94.8103 140.908C94.6254 140.97 94.5022 141.031 94.4405 141.155C94.3789 141.278 94.3789 141.402 94.4405 141.525C94.5022 141.648 94.5638 141.71 94.6871 141.772C94.8103 141.833 95.0569 141.895 95.4267 141.895C95.9197 141.895 96.2895 141.957 96.6593 142.019C97.0291 142.08 97.2756 142.142 97.5222 142.265C97.7687 142.389 97.9536 142.512 98.0769 142.635C98.2001 142.821 98.3234 143.006 98.385 143.191C98.5083 143.623 98.5083 144.054 98.4467 144.548C98.385 144.98 98.2001 145.412 97.892 145.782C97.5838 146.152 97.2756 146.522 96.9058 146.769C96.4744 147.077 96.043 147.262 95.5499 147.448C94.9952 147.633 94.4405 147.694 93.9475 147.633C93.4544 147.571 92.8997 147.448 92.345 147.077L93.2695 144.98C93.7626 145.165 94.194 145.288 94.5022 145.35C94.8103 145.412 95.1185 145.412 95.365 145.35C95.5499 145.288 95.6732 145.227 95.7348 145.103C95.7964 144.98 95.8581 144.856 95.7964 144.733C95.7348 144.61 95.6732 144.486 95.4883 144.486C95.3034 144.425 95.0569 144.425 94.6871 144.425C93.7626 144.425 93.023 144.301 92.5916 144.054C92.1601 143.869 91.852 143.499 91.7287 143.006C91.6054 142.512 91.5438 142.08 91.6671 141.648C91.7287 141.217 91.9136 140.846 92.1601 140.476C92.4066 140.106 92.7764 139.798 93.1462 139.489C93.5777 139.119 94.0707 138.872 94.6254 138.749Z" fill="#7497FF" />
    <path d="M88.4622 140.291C89.0785 140.229 89.5716 140.291 89.9414 140.6C90.3111 140.908 90.5577 141.34 90.6193 141.957C90.6809 142.265 90.6809 142.574 90.6193 143.006L90.2495 148.558L87.2911 148.99L87.5993 144.116C87.5993 143.931 87.5993 143.808 87.5993 143.684C87.5377 143.191 87.2911 143.006 86.9213 143.067C86.7365 143.067 86.5516 143.191 86.3667 143.314C86.1818 143.438 85.9352 143.746 85.6887 144.116L85.3189 149.237L82.3605 149.669L82.6687 144.61C82.6687 144.425 82.6687 144.301 82.6687 144.24C82.6071 143.993 82.4838 143.869 82.1756 143.869L82.3605 141.34C82.8536 141.155 83.285 141.031 83.5932 140.97C84.0246 140.908 84.3944 140.908 84.7026 141.093C85.0107 141.217 85.1956 141.463 85.2573 141.895C85.2573 141.957 85.2573 142.019 85.2573 142.019C85.2573 142.08 85.2573 142.142 85.2573 142.142C85.6271 141.587 86.1201 141.155 86.6132 140.846C87.1062 140.538 87.7842 140.353 88.4622 140.291Z" fill="#7497FF" />
    <path d="M79.094 141.278C79.772 141.278 80.265 141.402 80.5732 141.648C80.943 141.895 81.1279 142.327 81.1279 142.882C81.1279 143.006 81.1279 143.129 81.1279 143.252C81.1279 143.376 81.1279 143.499 81.0662 143.623L79.4022 152.877L76.4438 153L77.923 144.795C77.923 144.671 77.9846 144.548 77.9846 144.425C77.9846 144.054 77.7997 143.808 77.4299 143.869C77.3067 143.869 77.1834 143.869 77.0601 143.931C76.9369 143.993 76.752 144.054 76.6287 144.116V141.895C76.9985 141.71 77.4299 141.587 77.8614 141.463C78.2928 141.34 78.7242 141.34 79.094 141.278Z" fill="#7497FF" />
    <path d="M73.4854 141.278C73.7936 141.278 74.0401 141.34 74.2867 141.402C74.5332 141.463 74.7181 141.587 74.903 141.71C75.0879 141.833 75.2111 142.019 75.2728 142.265C75.3344 142.45 75.396 142.759 75.396 143.067C75.396 143.191 75.396 143.314 75.3344 143.438C75.3344 143.561 75.2728 143.684 75.2728 143.808L74.2867 147.509L75.2111 147.571L74.5948 149.792L73.6703 149.73L72.9924 152.26L70.034 152.075L70.712 149.545L69.2328 149.483L69.7875 147.262L71.3283 147.324L72.0679 144.61C72.0679 144.548 72.0679 144.486 72.0679 144.486C72.0679 144.425 72.0679 144.425 72.0679 144.363C72.0679 144.054 71.9446 143.931 71.6365 143.869C71.5132 143.869 71.3283 143.869 71.1434 143.931C70.9585 143.993 70.7736 143.993 70.5271 144.116L70.6503 141.772C71.1434 141.648 71.5748 141.463 72.0679 141.402C72.5609 141.278 73.054 141.278 73.4854 141.278Z" fill="#7497FF" />
    <path d="M67.014 140.723C67.692 140.846 68.3083 141.031 68.863 141.402C69.4177 141.71 69.8491 142.142 70.1573 142.635L68.4932 144.178C68.1234 143.746 67.7536 143.438 67.3838 143.191C67.014 142.944 66.6442 142.759 66.2744 142.759C66.0895 142.697 65.9046 142.759 65.843 142.821C65.7197 142.882 65.6581 143.006 65.6581 143.129C65.6581 143.252 65.6581 143.376 65.7813 143.499C65.9046 143.623 66.0895 143.746 66.3977 143.931C66.8291 144.116 67.1989 144.363 67.4454 144.548C67.7536 144.733 67.9385 144.918 68.1234 145.103C68.3083 145.288 68.4315 145.473 68.4932 145.658C68.5548 145.844 68.5548 146.09 68.5548 146.337C68.4932 146.769 68.3083 147.201 68.0618 147.571C67.8152 147.941 67.4454 148.25 67.0756 148.496C66.7058 148.743 66.2128 148.928 65.7197 148.99C65.2267 149.113 64.7336 149.113 64.1789 148.99C63.6242 148.928 63.0695 148.743 62.6381 148.496C62.2066 148.25 61.7752 147.879 61.4054 147.324L63.1311 145.782C63.5009 146.152 63.8091 146.46 64.1173 146.646C64.3638 146.831 64.672 146.954 64.9185 147.016C65.1034 147.016 65.2266 147.016 65.3499 146.954C65.4732 146.892 65.5348 146.769 65.5348 146.646C65.5348 146.522 65.4732 146.399 65.3499 146.275C65.2266 146.152 64.9801 146.029 64.672 145.844C63.8091 145.412 63.2544 145.042 62.8846 144.671C62.5148 144.301 62.4532 143.869 62.5148 143.314C62.5764 142.821 62.7613 142.45 63.0079 142.08C63.2544 141.71 63.5626 141.402 63.9324 141.217C64.3022 141.031 64.7336 140.846 65.2266 140.785C65.9046 140.6 66.3977 140.6 67.014 140.723Z" fill="#7497FF" />
    <path d="M55.3654 146.275L54.0711 147.139L54.934 147.756L53.9479 148.743L53.1467 147.879L52.592 149.052L51.1744 148.62L51.914 147.509L50.5581 147.694L50.8046 146.522L51.9756 146.584L51.914 145.288L53.2699 144.98L53.3316 146.337L54.6875 145.412L55.3654 146.275Z" fill="#7497FF" />
    <path d="M46.1205 143.252L43.6552 142.08L44.6413 140.846C44.025 141.155 43.4703 141.34 42.9156 141.402C42.3609 141.463 41.8679 141.402 41.4365 141.217C41.3132 141.155 41.2516 141.155 41.2516 141.093C41.1899 141.093 41.1899 141.031 41.1899 141.031L42.854 138.872C43.3471 139.119 43.8401 139.242 44.2715 139.366C44.7646 139.427 45.196 139.427 45.5658 139.304L48.6475 135.356L51.2977 136.651L46.1205 143.252Z" fill="#7497FF" />
    <path d="M42.4842 131.777C42.9156 132.086 43.2854 132.394 43.5936 132.765C43.9018 133.135 44.0867 133.567 44.1483 133.937C44.2716 134.369 44.2716 134.8 44.1483 135.232C44.0867 135.664 43.9018 136.158 43.5936 136.59C43.2238 137.206 42.7307 137.638 42.176 138.008C41.6213 138.379 41.005 138.625 40.3271 138.749C39.6491 138.872 38.9711 138.872 38.2932 138.749C37.6152 138.625 36.9373 138.379 36.3209 137.947C35.4581 137.392 34.9034 136.836 34.6569 136.158C34.4103 135.479 34.472 134.862 34.9034 134.245C35.3965 133.443 36.136 133.073 37.1222 133.135C38.1083 133.196 39.2793 133.69 40.6969 134.554L41.0667 134.8C41.3132 134.43 41.4364 134.122 41.3748 133.875C41.3748 133.628 41.1899 133.443 40.8818 133.258C40.3271 132.888 39.7107 132.888 39.156 133.135L37.6152 131.222C37.985 131.037 38.4164 130.975 38.7862 130.914C39.2177 130.852 39.6491 130.852 40.0805 130.914C40.512 130.975 40.9434 131.037 41.3748 131.16C41.7446 131.346 42.1144 131.531 42.4842 131.777ZM37.8618 136.528C38.1083 136.713 38.4164 136.713 38.7862 136.651C39.156 136.59 39.4642 136.404 39.834 136.158L39.7107 136.096C39.0328 135.664 38.5397 135.417 38.2316 135.294C37.9234 135.171 37.6769 135.294 37.5536 135.479C37.4303 135.602 37.4303 135.787 37.492 135.973C37.5536 136.219 37.6769 136.404 37.8618 136.528Z" fill="#7497FF" />
    <path d="M36.0128 127.274C36.5675 127.706 36.9989 128.199 37.3071 128.754C37.6152 129.31 37.8001 129.927 37.8001 130.482L35.5813 130.975C35.4581 130.42 35.3348 129.988 35.1499 129.556C34.965 129.125 34.7185 128.816 34.4103 128.631C34.2254 128.508 34.1022 128.446 33.9789 128.446C33.8556 128.446 33.7324 128.508 33.6707 128.631C33.6091 128.693 33.5475 128.816 33.6091 129.001C33.6707 129.186 33.7324 129.371 33.9173 129.68C34.1638 130.05 34.3487 130.42 34.5336 130.729C34.6569 131.037 34.7801 131.346 34.8418 131.592C34.9034 131.839 34.9034 132.086 34.8418 132.271C34.7801 132.456 34.7185 132.641 34.5336 132.888C34.2254 133.258 33.9173 133.505 33.4858 133.69C33.0544 133.875 32.623 133.998 32.1916 133.998C31.6985 133.998 31.2671 133.937 30.774 133.752C30.2809 133.567 29.8495 133.32 29.4181 133.011C28.9867 132.641 28.6169 132.271 28.3087 131.839C28.0622 131.407 27.8773 130.852 27.8156 130.235L30.096 129.803C30.2193 130.297 30.3426 130.729 30.4658 131.037C30.5891 131.346 30.774 131.592 30.9589 131.716C31.0822 131.839 31.2054 131.901 31.3903 131.901C31.5136 131.901 31.6369 131.839 31.7601 131.716C31.8218 131.592 31.8834 131.469 31.8218 131.346C31.7601 131.16 31.6369 130.975 31.452 130.605C30.8973 129.803 30.5891 129.186 30.5275 128.693C30.4658 128.199 30.5275 127.767 30.8973 127.335C31.2054 126.965 31.5752 126.657 31.945 126.472C32.3148 126.287 32.7462 126.163 33.1777 126.163C33.6091 126.163 34.1022 126.225 34.5336 126.41C35.0883 126.595 35.5813 126.904 36.0128 127.274Z" fill="#7497FF" />
    <path d="M31.2054 123.14C31.6369 123.572 31.8218 124.066 31.8218 124.498C31.8218 124.929 31.5752 125.423 31.0822 125.855C30.8973 126.04 30.5891 126.287 30.2193 126.472L25.4736 129.371L23.4397 127.212L27.5691 124.683C27.6924 124.621 27.8156 124.498 27.8773 124.436C28.2471 124.127 28.2471 123.819 28.0005 123.51C27.8773 123.387 27.6924 123.264 27.4458 123.202C27.1993 123.079 26.8911 123.079 26.3981 123.079L22.0222 125.793L19.9883 123.634L24.3026 120.981C24.4258 120.919 24.5491 120.796 24.6107 120.734C24.7956 120.549 24.7956 120.364 24.6107 120.117L26.7679 118.76C27.1993 119.069 27.5075 119.315 27.754 119.562C28.0622 119.871 28.2471 120.241 28.3087 120.549C28.3703 120.858 28.2471 121.166 27.9389 121.475C27.8773 121.536 27.8773 121.536 27.8156 121.598C27.754 121.598 27.754 121.66 27.6924 121.66C28.3703 121.66 28.9867 121.783 29.5413 122.03C30.2809 122.277 30.774 122.647 31.2054 123.14Z" fill="#7497FF" />
    <path d="M24.9805 116.046C25.3503 116.601 25.5352 117.033 25.5352 117.465C25.5352 117.896 25.2887 118.267 24.8573 118.637C24.7956 118.698 24.6724 118.76 24.5491 118.822C24.4258 118.883 24.3642 118.945 24.2409 119.007L15.7356 123.017L13.9483 120.611L21.5291 117.094C21.6524 117.033 21.7756 116.971 21.8373 116.909C22.1454 116.662 22.2071 116.416 21.9605 116.107C21.8989 116.046 21.7756 115.922 21.6524 115.86C21.5291 115.799 21.4058 115.675 21.2826 115.614L23.0699 114.38C23.4397 114.565 23.8095 114.812 24.1177 115.12C24.4875 115.429 24.734 115.737 24.9805 116.046Z" fill="#7497FF" />
    <path d="M21.7756 111.48C21.8989 111.727 22.0222 111.974 22.1454 112.221C22.2071 112.467 22.2687 112.714 22.2071 112.899C22.2071 113.146 22.1454 113.331 22.0222 113.516C21.8989 113.701 21.714 113.886 21.4675 114.071C21.3442 114.133 21.2826 114.195 21.1593 114.256C21.036 114.318 20.9128 114.38 20.7895 114.38L17.1532 115.737L17.6462 116.539L15.4891 117.341L14.996 116.539L12.5307 117.465L10.9283 114.935L13.3936 114.01L12.5924 112.714L14.7495 111.912L15.5507 113.208L18.1393 112.221C18.2009 112.221 18.2626 112.159 18.2626 112.159C18.2626 112.159 18.3242 112.097 18.3858 112.097C18.6324 111.912 18.694 111.727 18.5091 111.48C18.4475 111.357 18.3242 111.233 18.2009 111.11C18.0777 110.987 17.8928 110.863 17.7079 110.678L19.6801 109.444C20.0499 109.753 20.4813 110.061 20.7895 110.431C21.2209 110.74 21.5291 111.11 21.7756 111.48Z" fill="#7497FF" />
    <path d="M18.5091 105.866C18.694 106.298 18.8173 106.668 18.8173 107.1C18.8173 107.47 18.7556 107.84 18.5707 108.21C18.3858 108.581 18.2009 108.889 17.8928 109.198C17.5846 109.506 17.2148 109.753 16.7834 109.938C16.1671 110.246 15.4891 110.431 14.8111 110.493C14.1332 110.555 13.5169 110.493 12.9005 110.308C12.2842 110.123 11.7295 109.876 11.2981 109.506C10.805 109.136 10.4352 108.704 10.1887 108.149C10.0038 107.717 9.88053 107.223 9.94216 106.853C10.0038 106.421 10.1887 106.051 10.5585 105.743L9.38747 105.99L8.09318 103.275L12.9622 102.165C13.1471 102.103 13.2087 102.103 13.332 102.041C13.5785 101.918 13.6401 101.733 13.5169 101.486L15.9822 100.931C16.2903 101.363 16.5369 101.733 16.6601 102.041C16.845 102.473 16.9067 102.843 16.9067 103.152C16.845 103.46 16.6601 103.707 16.2287 103.892C16.1054 103.954 16.0438 103.954 15.9822 103.954C16.5985 104.077 17.0916 104.324 17.523 104.694C18.016 105.002 18.3242 105.434 18.5091 105.866ZM15.674 105.743C15.5507 105.496 15.4275 105.311 15.1809 105.188C14.9344 105.064 14.6879 104.941 14.3181 104.817L12.8389 105.188C12.5307 105.373 12.3458 105.619 12.2226 105.928C12.0993 106.236 12.0993 106.483 12.2226 106.73C12.3458 106.915 12.4691 107.1 12.654 107.223C12.8389 107.347 13.0854 107.47 13.332 107.532C13.5785 107.594 13.8867 107.594 14.1332 107.594C14.4413 107.594 14.6879 107.47 14.9344 107.347C15.3042 107.162 15.5507 106.977 15.674 106.668C15.8589 106.36 15.8589 106.051 15.674 105.743Z" fill="#7497FF" />
    <path d="M14.8111 96.8589C14.996 97.5375 15.1193 98.1544 15.0577 98.8331C14.996 99.5117 14.8111 100.067 14.503 100.56L12.4075 99.7585C12.5924 99.2649 12.7156 98.7714 12.7773 98.3395C12.8389 97.9077 12.7773 97.4758 12.7156 97.1673C12.654 96.9823 12.5924 96.8589 12.4691 96.7972C12.3458 96.7355 12.2226 96.7355 12.0993 96.7972C11.976 96.8589 11.9144 96.9206 11.8528 97.1056C11.7911 97.229 11.7295 97.5375 11.7295 97.846C11.7295 98.3395 11.7295 98.7097 11.6679 99.0798C11.6062 99.45 11.5446 99.6968 11.483 99.9436C11.4213 100.19 11.2981 100.375 11.1132 100.499C10.9899 100.622 10.7434 100.746 10.5585 100.807C10.1271 100.931 9.69563 100.992 9.2642 100.931C8.83278 100.869 8.40135 100.684 8.03155 100.437C7.66176 100.19 7.29196 99.8819 6.9838 99.45C6.67563 99.0798 6.4291 98.5863 6.30584 98.0927C6.12094 97.5375 6.05931 96.9822 6.05931 96.4887C6.05931 95.9952 6.2442 95.4399 6.55237 94.8847L8.70951 95.7484C8.52461 96.2419 8.40135 96.6738 8.33971 96.9823C8.27808 97.2907 8.27808 97.5992 8.40135 97.846C8.46298 98.0311 8.52461 98.1544 8.64788 98.2161C8.77114 98.2778 8.89441 98.2778 9.01767 98.2778C9.14094 98.2161 9.20257 98.1544 9.2642 97.9694C9.32584 97.7843 9.32584 97.5375 9.32584 97.1673C9.32584 96.2419 9.38747 95.5016 9.57237 95.0698C9.75726 94.6379 10.1271 94.3294 10.6201 94.1444C11.0516 94.021 11.5446 93.9593 11.976 94.021C12.4075 94.0827 12.8389 94.206 13.2087 94.4528C13.5785 94.6996 13.8867 95.0081 14.1948 95.4399C14.3797 95.8101 14.6262 96.3036 14.8111 96.8589Z" fill="#7497FF" />
    <path d="M4.64175 88.9621L3.28584 88.1601L3.10094 89.2089L1.80665 88.777L2.23808 87.7282H0.943796L0.697266 86.2476L2.05318 86.4327L1.31359 85.3222L2.48461 85.0137L2.97767 86.0625L4.08706 85.3839L4.94992 86.4944L3.71727 87.173L5.13482 87.975L4.64175 88.9621Z" fill="#7497FF" />
</svg>
