import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  display: flex;
  justify-content: space-between;
  outline: none;
  width: 100%;
  padding: 16px;
  background: linear-gradient(92.22deg, #ffffff -4.39%, #ffffff 101.36%);
  border: 1px solid #7497ff;
  border-radius: 8px;
  transition: 0.2s ease-in-out 0s;
  cursor: pointer;

  span.text-button {
    font-family: Raleway;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    width: 90%;
    text-align: left;
  }
  span.svg-icon {
    align-self: center;
  }

  :active {
    background: ${(props) => props.hoverColor && props.hoverColor};
    border: 1px solid ${(props) => props.borderColor && props.borderColor};
  }
  :active span.svg-icon path,
  :active span.svg-icon line {
    stroke: ${(props) => props.theme.black};
  }

  @media (min-width: 992px) {
    border-radius: 0;
    padding: 32px 50px;

    span.text-button {
      font-size: 20px;
    }

    :hover {
      transform: scale(1.01);
      background: ${(props) => props.hoverColor && props.hoverColor};
      border: 1px solid ${(props) => props.borderColor && props.borderColor};
    }
    :hover span.svg-icon path,
    :hover span.svg-icon line {
      stroke: ${(props) => props.theme.black};
    }
  }
`;

const ButtonSquare = ({ btnText, hoverColor, borderColor }) => {
  return (
    <StyledButton hoverColor={hoverColor} borderColor={borderColor}>
      <span className="text-button">{btnText}</span>
      <span className="svg-icon">
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 1H19V20" stroke="#7497FF" strokeWidth="2" />
          <line
            x1="18.9154"
            y1="1.70711"
            x2="0.707025"
            y2="19.9154"
            stroke="#7497FF"
            strokeWidth="2"
          />
        </svg>
      </span>
    </StyledButton>
  );
};

export default ButtonSquare;
