export const rulesSVG = <svg width="248" height="100" viewBox="0 0 248 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_9243_8271)">
        <path d="M239.769 10.2078L2.14941 35.6102L7.95769 89.8014L245.577 64.399L239.769 10.2078Z" fill="#4B4BFF" />
        <path d="M18.6474 81.3602L14.9148 46.3258L41.6749 43.4395L45.4075 78.4739L41.8182 78.8517L38.4327 47.1951L18.7659 49.2621L22.1514 80.9188L18.6474 81.3602Z" fill="white" />
        <path d="M52.2145 77.7731L48.4819 42.7387L61.9363 41.2845C62.3079 41.2296 62.6905 41.2493 63.0621 41.1944C63.519 41.2031 63.8906 41.1482 64.3586 41.2315C66.1973 41.3409 67.8571 41.7815 69.2004 42.6499C70.5438 43.5182 71.6972 44.6433 72.5231 46.1215C73.3379 47.5251 73.8143 49.2074 74.0043 51.0081C74.1944 52.8088 74.0652 54.5044 73.5423 56.1056C73.0195 57.7069 72.2296 59.0428 71.0351 60.2098C69.8406 61.3769 68.4313 62.1185 66.6064 62.6167C66.1715 62.7571 65.7256 62.823 65.354 62.8779C64.9081 62.9438 64.5365 62.9987 64.2393 63.0426L54.2999 64.1299L55.7295 77.4062L52.2145 77.7731ZM53.9417 60.6775L63.7325 59.6122C64.0298 59.5683 64.327 59.5244 64.773 59.4585C65.1445 59.4036 65.5051 59.2741 65.8657 59.1447C67.0217 58.7453 67.9961 58.1441 68.6401 57.3632C69.3585 56.5713 69.8208 55.5886 70.0492 54.5642C70.3519 53.5289 70.4206 52.4519 70.2554 51.3333C70.1755 50.2783 69.8616 49.1817 69.3468 48.2671C68.832 47.3525 68.1685 46.4599 67.3152 45.824C66.4618 45.188 65.4186 44.8087 64.1854 44.6861C63.8028 44.6664 63.4202 44.6467 63.0376 44.6271C62.655 44.6074 62.2834 44.6623 61.9862 44.7062L52.344 45.7496L53.9417 60.6775Z" fill="white" />
        <path d="M78.0829 75.0185L86.3816 38.664L91.0967 38.196L106.872 71.9849L103.134 72.3847L88.2397 40.4469L89.6628 40.3129L81.8208 74.6187L78.0829 75.0185ZM82.5825 65.8955L82.2354 62.5177L100.256 60.6176L100.603 63.9954L82.5825 65.8955Z" fill="white" />
        <path d="M111.278 71.4863L107.546 36.4519L121 34.9977C122.943 34.7869 124.719 34.9818 126.263 35.6681C127.807 36.3544 129.087 37.3083 130.029 38.5408C130.981 39.848 131.573 41.2845 131.741 42.9361C131.942 44.8114 131.59 46.5399 130.759 48.1105C129.928 49.6811 128.659 50.8591 127.101 51.6227L126.925 50.4295C129.265 50.8459 131.15 51.7865 132.656 53.2405C134.087 54.7055 134.979 56.6311 135.247 58.9539C135.47 60.9784 135.203 62.7704 134.52 64.319C133.838 65.8677 132.74 67.1729 131.278 68.0746C129.828 69.0509 128.014 69.6237 125.996 69.8455L111.278 71.4863ZM114.51 67.6561L124.672 66.5359C126.095 66.4019 127.325 65.9915 128.448 65.3684C129.497 64.7563 130.364 63.9424 130.89 62.8742C131.426 61.8805 131.633 60.707 131.509 59.3537C131.396 58.075 130.922 56.9257 130.248 55.9585C129.573 54.9913 128.624 54.2172 127.558 53.6888C126.493 53.1603 125.249 52.9631 123.985 53.1498L113.218 54.2832L114.51 67.6561ZM112.733 51.002L122.598 49.9257C123.724 49.8356 124.731 49.4582 125.546 48.8044C126.446 48.2143 127.09 47.4333 127.478 46.4616C127.94 45.4789 128.094 44.4655 127.992 43.2613C127.824 41.6097 127.106 40.3443 125.826 39.3903C124.546 38.4364 123.057 38.123 121.336 38.3009L111.471 39.3772L112.733 51.002Z" fill="white" />
        <path d="M163.833 65.8564L160.728 37.1301L144.548 67.9431L140.959 68.321L137.226 33.2865L140.816 32.9087L143.92 61.635L160.1 30.822L163.689 30.4441L167.422 65.4786L163.833 65.8564Z" fill="white" />
        <path d="M171.754 64.9909L171.429 61.7623C172.343 61.7797 173.075 61.5954 173.659 61.433C174.231 61.196 174.633 60.8318 175.002 60.2439C175.296 59.667 175.547 58.7918 175.69 57.7039C175.833 56.6159 175.995 55.1442 176.028 53.3105C176.124 51.3912 176.209 49.3974 176.231 47.4892C176.253 45.5809 176.264 43.5981 176.201 41.6263C176.137 39.6544 176.063 37.608 175.988 35.5616C175.914 33.5151 175.743 31.3306 175.646 29.135L197.702 26.7912L201.361 61.8366L197.771 62.2144L194.386 30.5577L179.36 32.1679C179.453 33.8304 179.484 35.5786 179.589 37.3157C179.693 39.0529 179.735 40.8756 179.787 42.7728C179.829 44.5955 179.807 46.5038 179.87 48.4756C179.859 50.4585 179.774 52.4523 179.711 54.5952C179.645 56.2052 179.483 57.677 179.299 58.9996C179.125 60.3968 178.759 61.5177 178.223 62.5114C177.686 63.5051 176.957 64.2224 175.96 64.6744C174.815 65.1484 173.466 65.2714 171.754 64.9909Z" fill="white" />
        <path d="M205.693 61.349L213.992 24.9944L218.707 24.5265L234.482 58.3154L230.744 58.7151L215.861 26.8519L217.284 26.7179L209.442 61.0237L205.693 61.349ZM210.204 52.3005L209.857 48.9228L227.878 47.0226L228.225 50.4004L210.204 52.3005Z" fill="white" />
    </g>
    <defs>
        <clipPath id="clip0_9243_8271">
            <rect width="241" height="64" fill="white" transform="translate(0.0170898 35.9532) rotate(-8.40323)" />
        </clipPath>
    </defs>
</svg>
