import { useState, useEffect, useRef, useCallback, memo } from "react";
//npm imports
import styled, { withTheme } from "styled-components/macro";

//component imports
import { Header25, Text18 } from "../Text/Text";

//other imports
import accordion_background from "./Static/accordion-background.png";
import accordion_mobile_background from "./Static/accordion-background-mobile.png";

//styled-components
const StyledAccordion = styled.div`
  width: 100%;
  border-top: 2px solid
    ${(props) =>
      !props.contentMaxHeight ? props.theme.darkGray : "transparent"};
  &:last-child {
    border-bottom: 2px solid ${(props) => props.theme.darkGray};
  }
  background-color: ${(props) => props.theme.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    width: 100%;
    padding: 40px 25px;
    background-color: transparent;
    background-image: ${(props) =>
      props.contentMaxHeight ? `url(${accordion_background})` : ""};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-image 0.2s linear;
    border: none;
    text-align: left;
    header {
      width: 90%;
      margin: 0 auto;
      max-width: 1235px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      transition: margin-bottom 0.2s linear;
      h5 {
        width: calc(100% - 44px);
        text-align: left;
      }
      .plus-minus-icon-container {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        align-self: flex-start;
        div {
          width: 24px;
          height: 2px;
          background-color: ${(props) =>
            props.contentMaxHeight ? props.theme.white : props.theme.violet};
          transition: background-color 0.2s linear;
        }
        .vertical {
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 0;
          transform: rotate(
            ${(props) => (props.contentMaxHeight ? "0deg" : "-90deg")}
          );
          transition: transform 0.2s linear;
        }
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  section {
    width: 90%;
    max-width: 1235px;
    max-height: ${(props) =>
      props.contentMaxHeight ? props.contentMaxHeight + 80 : 0}px;
    margin-top: ${(props) => (props.contentMaxHeight ? "40px" : "0px")};
    overflow: hidden;
    transition: max-height 0.2s ease-in-out, padding-bottom 0.2s linear,
      margin-top 0.2s linear;
    padding-bottom: ${(props) => props.contentMaxHeight && "40px"};

    ul {
        margin-bottom: 16px
    }

    li {
        list-style: circle;
        list-style-type: circle;
        list-style-position: inside;
    }
    p {
      text-align: left;
    }
  }
  @media (max-width: 1440px) {
    button {
      padding: 40px 0;
    }
  }
  @media (max-width: 586px) {
    padding: 0px;
    button {
      padding: 24px 0;
      background-image: ${(props) =>
        props.contentMaxHeight && `url(${accordion_mobile_background})`};
      header {
        margin-bottom: ${(props) => (props.contentMaxHeight ? "10px" : "0px")};
        ${Header25} {
          font-size: 18px;
          line-height: 24px;
          width: 80%;
        }
      }
    }
    section {
      p {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
`;

//main component
const Accordion = memo(({ header, content, propShowMore, theme }) => {
  const [showMore, setShowMore] = useState();
  const [contentMaxHeight, setContentMaxHeight] = useState(0);

  //accordion content refference use to toggle show and hide of the content
  const contentRef = useRef(null);

  //function to toggle show and hide of accordion content
  const toggleAccordion = useCallback(() => {
    setContentMaxHeight(showMore ? contentRef.current.scrollHeight + 20 : 0);
  }, [showMore, contentRef]);

  // //check if there is a true value for props.showMore and if so, setShowMore to be true so that this accordion item would be opened upon mount
  useEffect(() => {
    if (propShowMore === true) {
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  }, [propShowMore]);

  useEffect(() => {
    toggleAccordion();
  }, [showMore, toggleAccordion]);
  return (
    <StyledAccordion contentMaxHeight={contentMaxHeight}>
      <button onClick={(e) => setShowMore(!showMore)}>
        <header>
          <Header25 color={theme.white}>{header}</Header25>
          <div className="plus-minus-icon-container">
            <div className="horizontal"></div>
            <div className="vertical"></div>
          </div>
        </header>
      </button>
      <section ref={contentRef} className="answer">
        <Text18
          color={theme.white}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        >
          {/* {content} */}
        </Text18>
      </section>
    </StyledAccordion>
  );
});

export default withTheme(Accordion);
