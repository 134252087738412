import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { API } from "../../../../Consts/Api";
import { Text14, Text21 } from "../../../../Components/Text/Text";

// styled-components
const StyledCard = styled.div`
  cursor: pointer;
  position: relative;

  flex: 0 0 80%;
  transition: left 0.3s ease;
  z-index: ${(props) => props.zIndex};

  &.card:nth-of-type(1) {
    left: ${(props) => (props.activeCardIndex > 1 ? "-70%" : "0")};
  }

  &.card:nth-of-type(2) {
    left: ${(props) =>
      props.activeCardIndex === 2
        ? "-70%"
        : props.activeCardIndex > 2
        ? "-140%"
        : "-70%"};
    z-index: 3;
  }

  &.card:nth-of-type(3) {
    left: -140%;
    z-index: 2;
  }

  @media (max-width: 1025px) {
    flex: 0 0 85%;

    &.card:nth-of-type(1) {
      left: ${(props) => (props.activeCardIndex > 1 ? "-81%" : "-10%")};
    }

    &.card:nth-of-type(2) {
      left: ${(props) =>
        props.activeCardIndex === 2
          ? "-92%"
          : props.activeCardIndex > 2
          ? "-170%"
          : "-92%"};
      z-index: 3;
    }

    &.card:nth-of-type(3) {
      left: -170%;
      flex: 0 0 95%;
      z-index: 2;
    }
  }
`;
const StyledContainer = styled.section`
  position: relative;
  background-color: ${(props) => props.bgColor};
  height: 100%;
`;
const StyledStudentInfo = styled.section`
  margin-left: 115px;

  .student-field-of-study {
    font-size: 15px;
    line-height: 22px;
    text-transform: uppercase;
    max-width: 250px;
    color: ${(props) => props.color};
  }
`;
const StyledStudentName = styled.h2`
  font-family: Raleway;
  font-size: 130px;
  font-style: italic;
  font-weight: 200;
  line-height: 140px;
  text-transform: uppercase;
  color: ${(props) => props.color};
  position: relative;
  top: -28px;
`;
const StyledStudentPhoto = styled.div`
  &.student-photo {
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;

    img {
      display: block;
    }

    svg.star {
      position: absolute;
      bottom: 0;
      left: ${(props) => (props.isFirstCard ? "-25%" : "-10%")};
      transform: ${(props) =>
        props.isFirstCard ? `translateX(25%) ` : ` translateX(10%)`};
      z-index: 1;
    }
  }
`;
const StyledStudentTestimonial = styled.div`
  margin-top: 70px;
  padding: 40px 150px 115px 115px;
  position: relative;
  width: 70%;

  .testimonial {
    position: relative;
    z-index: 2;
    color: ${(props) => props.color};
  }

  svg {
    position: absolute;
  }

  svg.double-quote {
    top: 0;
    left: 0;
  }
`;
const StyledLinkSpan = styled.span`
  border-bottom: 1px solid ${(props) => props.borderColor};
  transition: all 0.2s ease-in-out;

  &:hover {
    border-bottom: 1px solid ${(props) => props.color};;
    color: ${(props) => props.borderColor};
  }
`;
const CarouselCardDesktop = ({
  testimonial,
  index,
  onCardClick,
  activeCardIndex,
  isFirstCard,
}) => {
  const { t } = useTranslation();

  const studentName = testimonial.name.split(" ");
  const studentLastName = studentName.pop();
  const studentFirstName = studentName.join(" ");

  return (
    <StyledCard
      className="card"
      key={testimonial.id}
      zIndex={4 - index}
      activeCardIndex={activeCardIndex}
      onClick={onCardClick}
    >
      <StyledContainer bgColor={testimonial.cardVariant.bgColor}>
        <StyledStudentInfo color={testimonial.cardVariant.color}>
          <StyledStudentName color={testimonial.cardVariant.color}>
            {studentFirstName} <br /> {studentLastName}
          </StyledStudentName>
          <Text14 className="student-field-of-study">
            {t("homepage.testimonials.student")}
            {testimonial.gender === "female" ? "ка" : ""}{" "}
            {t("homepage.testimonials.on")}{" "}
            <a
              href={API.createExternalLink(testimonial.link)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledLinkSpan borderColor={testimonial.cardVariant.fill} color={testimonial.cardVariant.color}>
                {testimonial.studies}
              </StyledLinkSpan>
            </a>
          </Text14>
        </StyledStudentInfo>
        <StyledStudentTestimonial color={testimonial.cardVariant.color}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="128"
            height="128"
            viewBox="0 0 128 128"
            fill="none"
            className="double-quote"
          >
            <path
              d="M-24.8302 128L-13.9137 68.8581H-34V0H37.6119V70.5479L20.5822 128H-24.8302ZM65.9946 128L76.9111 68.8581H56.8248V0H128V70.5479L111.407 128H65.9946Z"
              fill={testimonial.cardVariant.quoteFill}
            />
          </svg>

          <Text21 className="testimonial">{testimonial.testimonial}</Text21>
        </StyledStudentTestimonial>
        <StyledStudentPhoto className="student-photo" isFirstCard={isFirstCard}>
          <img src={testimonial.image} alt="" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="231"
            height="145"
            viewBox="0 0 231 145"
            fill="none"
            className="star"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M124.063 0H106.143V95.0819L40.0472 27.8466L27.3758 40.7365L93.4713 107.972H0V126.201H93.4671L27.3754 193.432L40.0468 206.322L106.143 139.086V234.173H124.063V139.091L190.154 206.322L202.826 193.432L136.734 126.201H230.204V107.972H136.73L202.825 40.7366L190.154 27.8468L124.063 95.0773V0Z"
              fill={testimonial.cardVariant.fill}
            />
          </svg>
        </StyledStudentPhoto>
      </StyledContainer>
    </StyledCard>
  );
};

export default CarouselCardDesktop;
